import React, { useState } from 'react';
import { Code, Globe, ArrowRight, BookOpen, Package,Info, Terminal, ChevronRight, Menu, X, Moon, Sun, Copy, Check,ListChecks } from 'lucide-react';
import { brokerParams, commonHeaders, commonText, sectionDescriptions, getParamDescription } from './apiConfig';
// import modelPfSection from './modelPfSection';
import { errorCodes } from './errorCodes';

const DocumentationLayout = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  const goToHome = () => {
    setActiveSection(null);
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    if (!darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  };

  const CopyButton = ({ text }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    };

    return (
      <button
        onClick={handleCopy}
        className="absolute top-2 right-2 p-1 rounded-md bg-gray-700 dark:bg-gray-600 text-white hover:bg-gray-600 dark:hover:bg-gray-500 transition-colors"
      >
        {copied ? <Check size={16} /> : <Copy size={16} />}
      </button>
    );
  };

  const CodeBlock = ({ code, language }) => (
    <div className="relative">
      <pre className="bg-gray-800 dark:bg-gray-900 text-green-400 p-4 rounded-md overflow-x-auto text-sm">
        <code className={`language-${language}`}>{code}</code>
      </pre>
      <CopyButton text={code} />
    </div>
  );

  const modelPfSection = {
    title: "Model PF",
    description: "This section provides an overview of Model Portfolio (PF) operations and endpoints.",
    content: `
      <div class="space-y-4">
        <p>The Model Portfolio (PF) API endpoints allow you to manage and interact with model portfolios. Here's a quick overview of the available operations:</p>
        <ul class="list-disc list-inside">
          <li><strong>Calculate Rebalance:</strong> Calculate the rebalance for a model portfolio.</li>
          <li><strong>Insert Investment Amount:</strong> Add investment amounts to a model portfolio subscription.</li>
          <li><strong>Process Trade:</strong> Execute trades for a model portfolio.</li>
          <li><strong>Get Repair:</strong> Retrieve repair information for failed trades across multiple models.</li>
        </ul>
        <p>Select a specific operation from the sidebar to view detailed API endpoints and parameters.</p>
      </div>
    `,
    subsections: {
      calculateRebalance: {
        title: "Allocate Portfolio",
        endpoint: " https://api.example.com/rebalance/allocate-portfolio",
        method: "POST",
        description: "Calculate the portfolio as per model.",
        requestBody: [
          { name: "userEmail", type: "string", description: "Email Id of the user or identifier of the user." },
          { name: "userBroker", type: "string", description: "Name of the broker." },
          { name: "modelName", type: "string", description: "Name of the model subsribed by user." },
          { name: "modelId", type: "string", description: "Identifier of the rebalances in the model portfolio." },
          { name: "advisor", type: "string", description: "Name of the advisor." },
          { name: "userFund", type: "string", description: "Funds available in user's broker account." },
          { name: "apiKey", type: "string", description: "Api key for authentication with broker." },
          { name: "apiSecret", type: "string", description: "Api secret for authentication with broker." },
          { name: "accessToken", type: "string", description: "Access Token for authentication with broker." }
        ],
        curl: `curl --location '/rebalance/allocate-portfolio' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{   
    "userEmail":"example@gmail.com",
    "userBroker":"broker",
    "modelName":"Alpha 50",
    "modelId":"02d73698-c64e-4371-bb69-bde511408a18",
    "advisor":"AlphaQuarkTest",
    "userFund":"486.13", // call the funds apis to fetch user balance
    "apiKey":"1843^B0323402s0S4X8EY361393", // apiKey, secretToken are user specific
    "secretKey":"389905xFe156p&1h44828H47Z4",
    "accessToken":"QUc1OTY1NTM6NDMwMDE5NzI=" // generated by hitting broker specific login api
}'`,
        payload: `{   
    "userEmail":"example@gmail.com",
    "userBroker":"broker",
    "modelName":"Alpha 50",
    "advisor":"AlphaQuarkTest",
    "model_id":"02d73698-c64e-4371-bb69-bde511408a18",
    "userFund":"486.13", # call the funds apis to fetch user balance
    "apiKey":"1843^B0323402s0S4X8EY361393", // apiKey, secretToken are user specific
    "secretKey":"389905xFe156p&1h44828H47Z4",
    "accessToken":"QUc1OTY1NTM6NDMwMDE5NzI=" // generated by hitting broker specific login api
}`,
        response: `{
    "buy": {
        "TATASTEEL-EQ": 26,
        "YESBANK-EQ": 256
    },
    "message": "User's portfolio value (9000) is below the allowed balance (10000.00)",
    "sell": {
            "IDEA-EQ": 100
    },
    "totalValue": 9000,
    "status": 0
}`
      },
      minimumPortfolioAmount: {
        title: "Minimum Investment Amount",
        endpoint: " https://api.example.com/rebalance/minimum-investment-amount",
        method: "POST",
        description: "Calculate the minimum portfolio amount required.",
        requestBody: [
          { name: "symbol", type: "string", description: "stock symbol." },
          { name: "value", type: "string", description: "distribution in model, sum of all the symbol values should be equal to 1." },
          { name: "exchange", type: "string", description: "exchange of stock." },
        ],
        curl: `curl --location '/rebalance/minimum-investment-amount' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
  "model_portfolio": [
    {
      "symbol": "KOTAKBANK-EQ",
      "value": "0.20",
      "exchange": "NSE"
    },
    {
      "symbol": "SBIN",
      "value": "0.30",
      "exchange": "BSE"
    },
    {
      "symbol": "ICICIBANK-EQ",
      "value": "0.50",
      "exchange": "NSE"
    }
  ]
}'`,
        payload: `{
  "model_portfolio": [
    {
      "symbol": "KOTAKBANK-EQ",
      "value": "0.20",
      "exchange": "NSE"
    },
    {
      "symbol": "SBIN",
      "value": "0.30",
      "exchange": "BSE"
    },
    {
      "symbol": "ICICIBANK-EQ",
      "value": "0.50",
      "exchange": "NSE"
    }
  ]
}`,
        response: `{
    "min_investment_amount": 18036.6,
    "min_investment_value": {
        "ICICIBANK-EQ": 8915.199999999999,
        "KOTAKBANK-EQ": 3538.9,
        "SBIN": 5582.5
    },
    "status": 0
}`
      },
      subscribe: {
        title: "Insert Investment Amount",
        endpoint: " https://api.example.com/rebalance/insert-investment-amount",
        method: "POST",
        description: "Insert investment amount required to subscribe model portfolio.",
        requestBody: [
          { name: "userEmail", type: "string", description: "Email Id of the user or identifier of the user." },
          { name: "modelName", type: "string", description: "Name of the model subsribed by user." },
          { name: "modelId", type: "string", description: "Identifier of the rebalances in the model portfolio." },
          { name: "advisor", type: "string", description: "Name of the advisor." },
          { name: "subscriptionAmountRaw", type: "string", description: "Amount required to subscribe model portfolio." }
        ],
        curl: `curl --location '/{broker}/subscribe' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
    "userEmail": "example@gmail.com",
    "modelName": "AlphaTest",
    "modelId": "12345465789",
    "advisor": "Alphaquark",
    "subscriptionAmountRaw": [
        {
            "amount": 50000,
            "dateTime": "2024-08-22T18:15:23.595+00:00"
        }
    ]
}'`,
        payload: `{
    "userEmail": "example@gmail.com",
    "modelName": "AlphaTest",
    "modelId": "12345465789",
    "advisor": "Alphaquark",
    "subscriptionAmountRaw": [
        {
            "amount": 50000,
            "dateTime": "2024-08-22T18:15:23.595+00:00"
        }
    ]
}`,
        response: `{
    "message": "success",
    "status": 0
}`
      },
      processTrade: {
        title: "Process Trade",
        endpoint: " https://api.example.com/rebalance/process-trade",
        method: "POST",
        description: "Process a trade for a model portfolio.",
        requestBody: [
          { name: "apiKey", type: "string", description: "Api key for authentication with broker." },
          { name: "apiSecret", type: "string", description: "Api secret for authentication with broker." },
          { name: "accessToken", type: "string", description: "Access Token for authentication with broker." },
          { name: "modelName", type: "string", description: "Name of the model subsribed by user." },
          { name: "modelId", type: "string", description: "The ID of the model portfolio." },
          { name: "uniqueId", type: "string", description: "The ID of the trade placed in model portfolio." },
          { name: "advisor", type: "string", description: "Name of the advisor." },
          { name: "userEmail", type: "string", description: "Email Id of the user or identifier of the user." },
          { name: "trades", type: "object", description: "The trade details." },
        ],
        curl: `curl --location '/{broker}/process-trade' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
  "apiKey": "1843^B0323402s0S4X8EY361393",
  "secretKey": "389905xFe156p&1h44828H47Z",
  "accessToken": "QUc1OTY1NTM6MjQwMjU2OTg=",
  "modelName": "Alpha 50",
  "modelId": "7085d1dc-86e1-46a0-b859-3bf58578a1c4",
  "uniqueId": "e9b4ef1b-346d-4b31-92ef-dcd99fcfb84c",
  "advisor": "AlphaQuarkTest",
  "userBroker": "Broker",
  "userEmail": "example@gmail.com",
  "trades": [
    {
      "transactionType": "SELL",
      "exchange": "NSE",
      "segment": "EQUITY",
      "productType": "DELIVERY",
      "orderType": "MARKET",
      "price": 0,
      "duration": "DAY",
      "tradingSymbol": "SBIN-EQ",
      "quantity": 1,
      "priority": 0,
      "trade_given_by": "advisor@company.com",
      "tradeId": "12456",
      "user_broker": "Broker"
    }
  ]
}
'`,
        payload: `{
  "apiKey": "1843^B0323402s0S4X8EY361393",
  "secretKey": "389905xFe156p&1h44828H47Z",
  "accessToken": "QUc1OTY1NTM6MjQwMjU2OTg=",
  "modelName": "Alpha 50",
  "modelId": "7085d1dc-86e1-46a0-b859-3bf58578a1c4",
  "uniqueId": "e9b4ef1b-346d-4b31-92ef-dcd99fcfb84c",
  "advisor": "AlphaQuarkTest",
  "userBroker": "Broker",
  "userEmail": "example@gmail.com",
  "trades": [
    {
      "transactionType": "SELL",
      "exchange": "NSE",
      "segment": "EQUITY",
      "productType": "DELIVERY",
      "orderType": "MARKET",
      "price": 0,
      "duration": "DAY",
      "tradingSymbol": "SBIN-EQ",
      "quantity": 1,
      "priority": 0,
      "trade_given_by": "advisor@company.com",
      "tradeId": "12456",
      "user_broker": "Broker"
    }
  ]
}
'`,
        response: `{
    "results": [
        {
            "averagePrice": 0.0,
            "disclosedQuantity": 0,
            "duration": "DAY",
            "exchange": "NSE",
            "filledShares": 0,
            "instrumentType": "",
            "lotsize": "",
            "message": "success.",
            "message_aq": "success.",
            "optionType": "",
            "orderId": "240917025139549",
            "orderStatus": "complete",
            "orderStatusMessage": "complete",
            "orderType": "MARKET",
            "orderUpdateTime": "2024-09-17 09:33:48",
            "price": 0.0,
            "productType": "Delivery",
            "quantity": 1,
            "rebalance_status": "success",
            "status": 0,
            "symbol": "SBIN-EQ",
            "tradeId": "12456",
            "transactionType": "SELL",
            "unfilledShares": 0,
            "uniqueOrderId": "240917025139549",
            "user_broker": "Broker",
            "variety": "SIMPLE"
        }
    ],
    "status": 0
}`
      },
      getRepair: {
        title: "Get Repair",
        endpoint: " https://api.example.com/rebalance/get-repair",
        method: "POST",
        description: "Retrieve repair information for failed trades across multiple models.",
        requestBody: [
          { name: "modelName", type: "array", description: "Array of model names to check for repairs." },
          { name: "advisor", type: "string", description: "Name of the advisor." },
          { name: "userEmail", type: "string", description: "Email of the user." }
        ],
        curl: `curl --location '/{broker}/rebalance/get-repair' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
  "modelName": ["New Test", "Alpha50"],
  "advisor": "AlphaQuarkTest",
  "userEmail": "Alphaquark@gmail.com"
}'`,
        payload: `{
  "modelName": ["New Test", "Alpha50"],
  "advisor": "AlphaQuarkTest",
  "userEmail": "Alphaquark@gmail.com"
}`,
        response: `{
  "message": "Repair data retrieved successfully",
  "models": [
    {
      "failedTrades": [],
      "message": "No failed trades found in the latest execution",
      "modelName": "Alpha50",
      "uniqueId": null,
      "userBroker": null
    },
    {
      "failedTrades": [],
      "message": "No failed trades found in the latest execution",
      "modelName": "New Test",
      "uniqueId": null,
      "userBroker": null
    }
  ],
  "status": 0
}`
      }
    }
  };

  const LoginSection = {
    title: "Login",
    description: "This section provides an overview of login methods for different brokers.",
    content: `
    <div class="space-y-4">
      <p>Our API supports multiple brokers, each with its own login method. Here's a quick overview:</p>
      <ul class="list-disc list-inside">
        <li><strong>Angelone:</strong> Uses API key, client code, password, and QR value for authentication.</li>
        <li><strong>HDFC Securities:</strong> Requires API key and redirects to HDFC's login page.</li>
        <li><strong>ICICI Direct:</strong> Uses API key and session for login.</li>
        <li><strong>IIFL:</strong> Requires client code, password, and a secondary PIN.</li>
        <li><strong>Upstox:</strong> Uses OAuth2 flow with API key, secret, and redirect URI.</li>
        <li><strong>Zerodha:</strong> Implements a two-step authentication process.</li>
      </ul>
      <p>Select a specific broker from the sidebar to view detailed login instructions and API endpoints.</p>
    </div>
  `,
    subsections: {

      aliceblue_login: {
        title: "AliceBlue Login",
        endpoint: "https://api.example.com/aliceblue/login",
        method: "POST",
        description: "Authenticates user with AliceBlue credentials.",
        note: "Note: All parameters must be included in the request body for successful authentication.",

        requestBody: [
          { name: "clientId", type: "string", description: "Unique client ID provided by AliceBlue" },
          { name: "apiKey", type: "string", description: "API key provided by AliceBlue for authentication" }
        ],
        curl: `curl --location '/aliceblue/login' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
    "clientId": "254555",
    "apiKey": "MC0J8XoGa5UxenRQ4zvSgPyv2vJjnX4FqMKUdTAB3YcfLrithm9w"
}'`,
        payload: `{
    "clientId": "254555",
    "apiKey": "MC0J8XoGa5UxenRQ4zvSgPyv2vJjnX4FqMKUdTAB3YcfLrithm9w"
}`,
        response: `{
    "message": "Login successful",
    "accessToken": "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICIyam9lOFVScGxZU3FTcDB3RDNVemVBQkgxYkpmOE4wSDRDMGVVSWhXUVAwIn0.eyJleHAiOjE3MzI4NjYxOTUsImlhdCI6MTcyNzY4MjE5NSwianRpIjoiZjRiNjdlMzItMjRiYy00MjJjLWJjOGYtMTNlMTZiMTNhMjVkIiwiaXNzIjoiaHR0cHM6Ly9hYjEuYW1vZ2EudGVjaC9hbXNzby9yZWFsbXMvQWxpY2VCbHVlIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6IjAxZDdmMmRlLTM1ODUtNDQ5Ny04M2UwLTA5NmIyMjg5YjczZiIsInR5cCI6IkJlYXJlciIsImF6cCI6ImFsaWNlLWtiIiwic2Vzc2lvbl9zdGF0ZSI6IjQxNDgwM2ZhLWQyODItNDc0YS04ZGM2LWI3ZTdlODkwY2UzZCIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cDovL2xvY2FsaG9zdDozMDAyIiwiaHR0cDovL2xvY2FsaG9zdDo1MDUwIiwiaHR0cDovL2xvY2FsaG9zdDo5OTQzIiwiaHR0cDovL2xvY2FsaG9zdDo5MDAwIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsImRlZmF1bHQtcm9sZXMtYWxpY2VibHVla2IiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFsaWNlLWtiIjp7InJvbGVzIjpbIkdVRVNUX1VTRVIiLCJBQ1RJVkVfVVNFUiJdfSwiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJlbWFpbCBwcm9maWxlIiwic2lkIjoiNDE0ODAzZmEtZDI4Mi00NzRhLThkYzYtYjdlN2U4OTBjZTNkIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInVjYyI6IjI1NDU1NSIsImNsaWVudFJvbGUiOlsiR1VFU1RfVVNFUiIsIkFDVElWRV9VU0VSIl0sIm5hbWUiOiIuIiwibW9iaWxlIjoiNzM0OTI5MDQ0NCIsInByZWZlcnJlZF91c2VybmFtZSI6IjI1NDU1NSIsImdpdmVuX25hbWUiOiIiLCJmYW1pbHlfbmFtZSI6Ii4iLCJlbWFpbCI6InByYXRpazE3NjIwMTJAZ21haWwuY29tIn0.YZQQd2Ej9sculNqseH5uHLFonP-MmRaJKCNwdQMj0bMwIiBF6IY3gqlM-jc9YLojBGIbz2JiLv8pRbzKbqGTO1SAXRybcoZvtA2ox75DY-Gx6U-GHEwwmxHfZHrnqj8Iz_8bgqt8mrfyWjvbOXmtC4ni6n2oWzaUFsOg1_PYktAAZBR5qWrThA8qYldWBQTaM2b9BBfUWcgff-3zRQExhiiLsdpgJHP8VlnAMdCAVhxzFV9S4VoX_fj6R3cywTvqN9ijE2jLsneOwD-IkEgxZH6LXdZYSIwxPZNjQmmMo-7FPEkobNDAEC14FtLKS094oZ35U3aKHYC-LNnOQ2dDgg",
    "status": 0
}`
      },


      login: {
        title: "Angelone",
        endpoint: "https://api.example.com/angelone/login",
        method: "POST",
        description: "Logs in user with Angelone.",
        note: "Note: All parameters must be included in the request body for successful authentication.",

        requestBody: [
          {
            "name": "apiKey",
            "type": "string",
            "description": "Unique API key provided by the broker for authenticating API requests."
          },
          {
            "name": "clientCode",
            "type": "string",
            "description": "Unique identifier assigned to the client by the broker, used for account identification during authentication."
          },
          {
            "name": "password",
            "type": "string",
            "description": "User's account pin for authenticating with the broker's system."
          },
          {
            "name": "qrValue",
            "type": "string",
            "description": "Encoded value from a QR code, used in two-factor authentication or for quick login processes with the broker."
          }
        ],
        curl: `curl --location '/rebalance/calculate-rebalance' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
  "apiKey": "EfkDdLMn",
  "clientCode": "Z13512746",
  "password": "7788",
  "qrValue": "44JUCSQKSKHGS6E55DTOFB4LI4"
  
}'`,
        payload: `{
  "apiKey": "EfkDdLMn",
  "clientCode": "Z13512746",
  "password": "7788",
  "qrValue": "44JUCSQKSKHGS6E55DTOFB4LI4"
  
}`,
        response: `{
    "data": {
        "feedToken": "eyJhbGciOiJIUzUxMiJ9.eyJ1c2VybmFtZSI6IlY1OTUxMjc0NiIsImlhdCI6MTcyNzQ2NzMyOCwiZXhwIjoxNzI3NTUzNzI4fQ.ZvOQb1igxSwkO2FveOkF8NQWovMUmcU5SLEOs3JMzlMAObETcDswt91tak5eFwmX9zHaLwJausA-zKlEMvbTSA",
        "accessToken": "Bearer eyJhbGciOiJIUzUxMiJ9.eyJ1c2VybmFtZSI6IlY1OTUxMjc0NiIsInJvbGVzIjowLCJ1c2VydHlwZSI6IlVTRVIiLCJ0b2tlbiI6ImV5SmhiR2NpT2lKU1V6STFOaUlzSW5SNWNDSTZJa3BYVkNKOS5leUoxYzJWeVgzUjVjR1VpT2lKamJHbGxiblFpTENKMGIydGxibDkwZVhCbElqb2lkSEpoWkdWZllXTmpaWE56WDNSdmEyVnVJaXdpWjIxZmFXUWlPallzSW5OdmRYSmpaU0k2SWpNaUxDSmtaWFpwWTJWZmFXUWlPaUk1TkRBME5UbGxaaTB5TVRWaExUTmlOV1V0T1dSa09TMWhNREUwTXpVek1UQTJOallpTENKcmFXUWlPaUowY21Ga1pWOXJaWGxmZGpFaUxDSnZiVzVsYldGdVlXZGxjbWxrSWpvMkxDSndjbTlrZFdOMGN5STZleUprWlcxaGRDSTZleUp6ZEdGMGRYTWlPaUpoWTNScGRtVWlmWDBzSW1semN5STZJblJ5WVdSbFgyeHZaMmx1WDNObGNuWnBZMlVpTENKemRXSWlPaUpXTlRrMU1USTNORFlpTENKbGVIQWlPakUzTWpjMU5qWXlOREVzSW01aVppSTZNVGN5TnpRMk56STJPQ3dpYVdGMElqb3hOekkzTkRZM01qWTRMQ0pxZEdraU9pSTNNekkyWW1KaVpDMHdOek01TFRReVpUSXRPVEF6T0MweFpXTTRNbVppWW1abU1UQWlmUS5kUlE0MlN3a1J1WG9TWFpNV0d0OHRDWUVfUkVNLUYwRkxlZG5JNl8xOVpMaXBWNVRaUzk0cGFYUkVVX05lX2J1dUM4R1JJZkgyQkpfbDFLN3NsNmNhajBRTWtBaUpLaDhKWE5MUFNPRlRCeExRUjRmdXEyUUduVDFVTjRHY1hMaWpHM2toc0o2TmJiRXpNTkpzVEVSVFVuVzBEZ2N5UlNWLVJ2NmZNX083SHMiLCJBUEktS0VZIjoiRWZrRGRKTUgiLCJpYXQiOjE3Mjc0NjczMjgsImV4cCI6MTcyNzU2NjI0MX0.FJBciOUb6PwitkrqRsLeeNAR-00Gu8Otm8j_WsXMJtXM0lcrscMqUHxd7CNQHcRCGBmxbwOBU_pHzUInSav1HA",
        "refreshToken": "eyJhbGciOiJIUzUxMiJ9.eyJ0b2tlbiI6IlJFRlJFU0gtVE9LRU4iLCJSRUZSRVNILVRPS0VOIjoiZXlKaGJHY2lPaUpTVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjFjMlZ5WDNSNWNHVWlPaUpqYkdsbGJuUWlMQ0owYjJ0bGJsOTBlWEJsSWpvaWRISmhaR1ZmY21WbWNtVnphRjkwYjJ0bGJpSXNJbWR0WDJsa0lqb3dMQ0prWlhacFkyVmZhV1FpT2lJNU5EQTBOVGxsWmkweU1UVmhMVE5pTldVdE9XUmtPUzFoTURFME16VXpNVEEyTmpZaUxDSnJhV1FpT2lKMGNtRmtaVjlyWlhsZmRqRWlMQ0p2Ylc1bGJXRnVZV2RsY21sa0lqb3dMQ0pwYzNNaU9pSnNiMmRwYmw5elpYSjJhV05sSWl3aWMzVmlJam9pVmpVNU5URXlOelEySWl3aVpYaHdJam94TnpJM05qUXdNVEk0TENKdVltWWlPakUzTWpjME5qY3lOamdzSW1saGRDSTZNVGN5TnpRMk56STJPQ3dpYW5ScElqb2laRE5oTkdKa05EZ3RaVEkyTUMwME5qUmxMV0ZpTlRZdE1qUm1PR0UxWTJSalkyVTRJbjAuSXY5UC1XQ2NkYlBEU3FidGZ4YUdVVFRYanEzQ1RHU1pmaU5zZjFQdE9DNU9ITV9wSlA3ZmlpZ0lObmlfUkx6b0ZWTEQ1NGctR0YzcXBhcUFnS19aczkyZ3NlakdLRTl0dVFPaG43TTJKa2ZPTlZFMUV0SUVPUTlTQVFyV3RfRFpoYWpBQndiTmNJWDBTS1FCWGlKam05MFp4YkktZEQyVUNDRHlqdThqMmNjIiwiaWF0IjoxNzI3NDY3MzI4fQ.TBXriEMkkNufYdpDBu75VMQrFOTyQPYmXILVpJ4pSmLT7oHr-kOddZ8u4jf8yMo8RiAgJQncfwwkp-y381-0YQ"
    },
    "errorcode": "",
    "message": "SUCCESS",
    "status": true
}`
      },
      angelone_publisher_login: {
        title: "AngelOne Publisher Login",
        endpoint: "https://api.example.com/angelone/login-url?apiKey=your_api_key",
        method: "GET",
        description: "Redirects user to angelone's site for login. After successful login a accessToken is provided in url arg, that can is to used to hit other endpoints.",
        note: "Note: All parameters must be included in the request body for successful authentication.",

        requestBody: [
          { name: "None", type: "None", description: "No request body for GET request." }
        ],
        curl: `curl --location '/angelone/login-url?apiKey=your_api_key' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\`,
        payload: `{
    no payload for GET request
}`,
        response: `https://smartapi.angelbroking.com/publisher-login?api_key=your_api_key"`
      },

      fyers_login: {
        title: "Fyers Login",
        endpoint: "https://api.example.com/fyers/login-url",
        method: "GET",
        description: "Redirects user to fyers site for login. ",
        note: "Note: All parameters must be included in the request body for successful authentication.",

        requestBody: [
          { name: "redirectUrl", type: "string", description: "The URL where the user will be redirected after successful authentication" },
          { name: "clientId", type: "string", description: "Unique client ID provided by Fyers for API authentication" },
          { name: "clientSecret", type: "string", description: "Secret key paired with client ID for secure API authentication" }
        ],
        curl: `curl --location '/fyers/login-url' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
     "redirectUrl": "https://www.example.com/",
    "clientId": "MCU9DGUIM9-100",
    "clientSecret": "YVEHGP48PF"
}'`,


        payload: `{
  "redirectUrl": "https://www.example.com/",
  "clientId": "MCU9DGUIM9-100",
  "clientSecret": "YVEHGP48PF"
}`,
        response: `"https://api-t1.fyers.in/api/v3/generate-authcode?client_id=MCU9DGUIM9-100&redirect_uri=https://www.iamclearmind.com/&response_type=code&state=sample_state"`
      },
      fyersAccessToken: {
        title: "Fyers AccessToken",
        endpoint: "https://api.example.com/fyers/gen-access-token",
        method: "POST",
        description: "Generates accessToken to be used for subsequent requests.",
        note: "Note: All parameters must be included in the request body for successful authentication.",

        requestBody: [
          { name: "clientId", type: "string", description: "Unique client ID provided by Fyers for API authentication" },
          { name: "clientSecret", type: "string", description: "Secret key paired with client ID for secure API authentication" },
          { name: "authCode", type: "string", description: "JWT token received from Fyers login process" }
        ],
        curl: `curl --location '/fyers/gen-access-token' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\ 
--header 'Content-Type: application/json' \\
--data '{
    "clientId":"MCU9DGUIM9-100",
    "clientSecret":"YVEHGP48PF",
    "authCode": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhcGkubG9naW4uZnllcnMuaW4iLCJpYXQiOjE3MzAxOTA4ODgsImV4cCI6MTczMDIyMDg4OCwibmJmIjoxNzMwMTkwMjg4LCJhdWQiOiJbXCJ4OjBcIiwgXCJ4OjFcIiwgXCJ4OjJcIiwgXCJkOjFcIiwgXCJkOjJcIiwgXCJ4OjFcIiwgXCJ4OjBcIl0iLCJzdWIiOiJhdXRoX2NvZGUiLCJkaXNwbGF5X25hbWUiOiJZTjEwMTI4Iiwib21zIjoiSzEiLCJoc21fa2V5IjoiMjk5ZjNkNmZkNjA3NjBjZTY4MzIyOTc3YzcwODQyMmZiN2YyYzFjYjY4MzBkZDQ3ZDk1MmNlNzkiLCJub25jZSI6IiIsImFwcF9pZCI6Ik1DVTlER1VJTTkiLCJ1dWlkIjoiOTFiZjE0MWMzYzNjNDg1YWI4ZjQzYzExMDU5MjJiMjciLCJpcEFkZHIiOiIwLjAuMC4wIiwic2NvcGUiOiIifQ.uXPWwVLehrTHVAhqIKrlXmoGDS7KgvMb9dsOg7ISEOw"
}'`,
        payload: `{
    "clientId":"MCU9DGUIM9-100",
    "clientSecret":"YVEHGP48PF",
    "authCode": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhcGkubG9naW4uZnllcnMuaW4iLCJpYXQiOjE3MzAxOTA4ODgsImV4cCI6MTczMDIyMDg4OCwibmJmIjoxNzMwMTkwMjg4LCJhdWQiOiJbXCJ4OjBcIiwgXCJ4OjFcIiwgXCJ4OjJcIiwgXCJkOjFcIiwgXCJkOjJcIiwgXCJ4OjFcIiwgXCJ4OjBcIl0iLCJzdWIiOiJhdXRoX2NvZGUiLCJkaXNwbGF5X25hbWUiOiJZTjEwMTI4Iiwib21zIjoiSzEiLCJoc21fa2V5IjoiMjk5ZjNkNmZkNjA3NjBjZTY4MzIyOTc3YzcwODQyMmZiN2YyYzFjYjY4MzBkZDQ3ZDk1MmNlNzkiLCJub25jZSI6IiIsImFwcF9pZCI6Ik1DVTlER1VJTTkiLCJ1dWlkIjoiOTFiZjE0MWMzYzNjNDg1YWI4ZjQzYzExMDU5MjJiMjciLCJpcEFkZHIiOiIwLjAuMC4wIiwic2NvcGUiOiIifQ.uXPWwVLehrTHVAhqIKrlXmoGDS7KgvMb9dsOg7ISEOw"
}'`,
        response: `{
  "accessToken": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhcGkuZnllcnMuaW4iLCJpYXQiOjE3MzAxOTEwNDIsImV4cCI6MTczMDI0ODIyMiwibmJmIjoxNzMwMTkxMDQyLCJhdWQiOlsieDowIiwieDoxIiwieDoyIiwiZDoxIiwiZDoyIiwieDoxIiwieDowIl0sInN1YiI6ImFjY2Vzc190b2tlbiIsImF0X2hhc2giOiJnQUFBQUFCbklKN0N4TENBYVhQek1sSzhvSl9lNnpkZk1HNjdiZ1FOZDAwZTlfUUNrc0ZEam02eEpUcHFVZHRpTWZDVGFKX3dhU0Jwb3hvX1U2ekt0NWFUMmF1UzNvdmZubTV5SDZJSkh6ZnV3NWMzOEFqbDlvZz0iLCJkaXNwbGF5X25hbWUiOiJOSU5HQU5OQSBSQU1FU0ggS1VOVE9KSSIsIm9tcyI6IksxIiwiaHNtX2tleSI6IjI5OWYzZDZmZDYwNzYwY2U2ODMyMjk3N2M3MDg0MjJmYjdmMmMxY2I2ODMwZGQ0N2Q5NTJjZTc5IiwiZnlfaWQiOiJZTjEwMTI4IiwiYXBwVHlwZSI6MTAwLCJwb2FfZmxhZyI6Ik4ifQ.RNag0MP87urX3UKekPdp3VxC8LcmudUpKbWOG8fvI6w",
  "message": "",
  "status": 0
}`
      },








      //       dhan: {
      //       title: "Dhan Login",
      //       endpoint: "/dhan/login-url",
      //       method: "POST",
      //       description: "Redirects user to Dhan's site for login.",
      //       requestBody: [
      //         { name: "apiKey", type: "string", description: "Unique API key provided by the broker for authenticating API requests." }
      //       ],
      //       curl: `curl --location '/dhan/login-url' \\
      // --header 'aq-api-key: 12345' \\
      // --header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
      // --header 'Content-Type: application/json' \\
      // --data '{
      //     "apiKey": "9T512xQ05Iw623738g2Y122VrB88"
      // }'`,
      //       payload: `{
      //     "apiKey": "9T512xQ05Iw623738g2Y122VrB88"
      // }`,
      //       response: `"https://api.icicidirect.com/apiuser/login?api_key=9T512xQ05Iw623738g2Y122VrB88"`
      //     },
      //     dhanAccessToken: {
      //       title: "Dhan AccessToken",
      //       endpoint: "/dhan/gen-access-token",
      //       method: "POST",
      //       description: "Generates accessToken to be used for subsequent requests.",
      //       requestBody: [
      //         { name: "apiKey", type: "string", description: "Unique API key provided by the broker for authenticating API requests." },
      //         { name: "apiSession", type: "string", description: "Received as a URL parameter upon redirection after successful login on the broker's site." }
      //       ],
      //       curl: `curl --location '/dhan/gen-access-token' \\
      // --header 'aq-api-key: 12345' \\
      // --header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
      // --header 'Content-Type: application/json' \\
      // --data '{
      //     "apiKey": "9T512xQ05Iw623738g2Y122VrB88",
      //     "apiSession": "44292665"
      // }'`,
      //       payload: `{
      //     "apiKey": "9T512xQ05Iw623738g2Y122VrB88",
      //     "apiSession": "44292665"
      // }`,
      //       response: `{
      //     "Error": null,
      //     "Status": 200,
      //     "Success": {
      //         "commodity_allowed": "C",
      //         "commodity_exchange_status": "Y",
      //         "commodity_trade_date": "17-Jun-2024",
      //         "exg_status": {
      //             "BSE": "C",
      //             "FNO": "Y",
      //             "NDX": "C",
      //             "NSE": "C"
      //         },
      //         "exg_trade_date": {
      //             "BSE": "18-Jun-2024",
      //             "FNO": "18-Jun-2024",
      //             "NDX": "18-Jun-2024",
      //             "NSE": "18-Jun-2024"
      //         },
      //         "idirect_ORD_TYP": "",
      //         "idirect_lastlogin_time": "17-Jun-2024 00:50:20",
      //         "idirect_user_name": "AKSHATA GORAKHNATH KENJALE",
      //         "idirect_userid": "AG596553",
      //         "mf_holding_mode_popup_flg": "N",
      //         "segments_allowed": {
      //             "Currency": "Z",
      //             "Derivatives": "Z",
      //             "Equity": "Y",
      //             "Trading": "Y"
      //         },
      //         "accessToken": "QUc1OTY1NTM6NTY3NTI5MTg="
      //     }
      // }`
      //     },
      hdfc: {
        title: "HdfcSec Login",
        endpoint: "https://api.example.com/hdfc/login-url",
        method: "POST",
        description: "Redirects user to Hdfc's site for login.",
        note: "Note: All parameters must be included in the request body for successful authentication.",

        requestBody: [
          { name: "apiKey", type: "string", description: "Unique API key provided by the broker for authenticating API requests." }
        ],
        curl: `curl --location '/hdfc/login-url' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
    "apiKey": "9T512xQ05Iw623738g2Y122VrB88"
}'`,
        payload: `{
    "apiKey": "9T512xQ05Iw623738g2Y122VrB88"
}`,
        response: `https://developer.hdfcsec.com/oapi/v1/login?api_key=9T512xQ05Iw623738g2Y122VrB88"`
      },
      hdfcAccessToken: {
        title: "HdfcSec AccessToken",
        endpoint: "https://api.example.com/hdfc/gen-access-token",
        method: "POST",
        description: "Generates accessToken to be used for subsequent requests.",
        note: "Note: All parameters must be included in the request body for successful authentication.",

        requestBody: [
          { name: "apiKey", type: "string", description: "Unique API key provided by the broker for authenticating API requests." },
          { name: "apiSecret", type: "string", description: "Confidential key paired with the API key, used to sign requests and enhance security in broker authentication." },
          { name: "requestToken", type: "string", description: "Received as a URL parameter upon redirection after successful login on the broker's site." }
        ],
        curl: `curl --location '/hdfc/gen-access-token' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
    "apiKey":"af2834ab18094902fdjhfjdf93d15fdf4",
    "apiSecret":"efb1fa19b9ae4fhfdfhdjf3fa3ec2bc20",
    "requestToken": "3e43344d73f04ca097e631e2233d40941102328758"
}'`,
        payload: `{
    "apiKey":"af2834ab18094902fdjhfjdf93d15fdf4",
    "apiSecret":"efb1fa19b9ae4fhfdfhdjf3fa3ec2bc20",
    "requestToken": "3e43344d73f04ca097e631e2233d40941102328758"
}`,
        response: `{
    "accessToken": "eyJhbGciOiJIUzM4NCJ9.eyJzdWMjk2NDJ9.uh7ddqP-5VpawCvehUJMzmCf",
    "status": 0
}`
      },
      icici: {
        title: "Icici Login",
        endpoint: "https://api.example.com/icici/login-url",
        method: "POST",
        description: "Redirects user to Icici's site for login.",
        note: "Note: All parameters must be included in the request body for successful authentication.",

        requestBody: [
          { name: "apiKey", type: "string", description: "Unique API key provided by the broker for authenticating API requests." }
        ],
        curl: `curl --location '/icici/login-url' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
    "apiKey": "9T512xQ05Iw623738g2Y122VrB88"
}'`,
        payload: `{
    "apiKey": "9T512xQ05Iw623738g2Y122VrB88"
}`,
        response: `"https://api.icicidirect.com/apiuser/login?api_key=9T512xQ05Iw623738g2Y122VrB88"`
      },
      iciciAccessToken: {
        title: "Icici AccessToken",
        endpoint: "https://api.example.com/icici/gen-access-token",
        method: "POST",
        description: "Generates accessToken to be used for subsequent requests.",
        note: "Note: All parameters must be included in the request body for successful authentication.",

        requestBody: [
          { name: "apiKey", type: "string", description: "Unique API key provided by the broker for authenticating API requests." },
          { name: "apiSession", type: "string", description: "Received as a URL parameter upon redirection after successful login on the broker's site." }
        ],
        curl: `curl --location '/icici/gen-access-token' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
    "apiKey": "9T512xQ05Iw623738g2Y122VrB88",
    "apiSession": "44292665"
}'`,
        payload: `{
    "apiKey": "9T512xQ05Iw623738g2Y122VrB88",
    "apiSession": "44292665"
}`,
        response: `{
    "Error": null,
    "Status": 200,
    "Success": {
        "commodity_allowed": "C",
        "commodity_exchange_status": "Y",
        "commodity_trade_date": "17-Jun-2024",
        "exg_status": {
            "BSE": "C",
            "FNO": "Y",
            "NDX": "C",
            "NSE": "C"
        },
        "exg_trade_date": {
            "BSE": "18-Jun-2024",
            "FNO": "18-Jun-2024",
            "NDX": "18-Jun-2024",
            "NSE": "18-Jun-2024"
        },
        "idirect_ORD_TYP": "",
        "idirect_lastlogin_time": "17-Jun-2024 00:50:20",
        "idirect_user_name": "AKSHATA GORAKHNATH KENJALE",
        "idirect_userid": "AG596553",
        "mf_holding_mode_popup_flg": "N",
        "segments_allowed": {
            "Currency": "Z",
            "Derivatives": "Z",
            "Equity": "Y",
            "Trading": "Y"
        },
        "accessToken": "QUc1OTY1NTM6NTY3NTI5MTg="
    }
}`
      },
      iifl_login_url: {
        title: "IIFL Login",
        endpoint: "https://api.example.com/iifl/login-url",
        method: "POST",
        description: "Get IIFL login URL for authentication initiation.",
        note: "Note: All parameters must be included in the request body for successful authentication.",

        requestBody: [],
        curl: `curl --location '/iifl/login-url' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json'`,
        payload: `{}`,
        response: `{
    "loginUrl": "https://api.iifl.com/auth/login?appKey=xyz123",
    "status": 0
}`
      },
      iifl_login_client: {
        title: "IIFL AccessToken",
        endpoint: "https://api.example.com/iifl/login/client",
        method: "POST",
        description: "Complete IIFL authentication using client code and auth code received after redirect.",
        requestBody: [
          { name: "clientCode", type: "string", description: "Client code received from IIFL redirect" },
          { name: "authCode", type: "string", description: "Authentication code received from IIFL redirect" }
        ],
        curl: `curl --location '/iifl/login/client' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
    "clientCode": "68451704",
    "authCode": "IRLF4ROKT1BPBMP4MFEV"
}'`,
        payload: `{
    "clientCode": "68451704",
    "authCode": "IRLF4ROKT1BPBMP4MFEV"
}`,
        response: `{
    "message": "Login successful",
    "accessToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...",
    "status": 0
}`
      },

      kotak_get_otp: {
        title: "Kotak AccessToken",
        endpoint: "https://api.example.com/kotak/get-otp",
        method: "POST",
        description: "Generate OTP and access tokens for Kotak authentication.",
        note: "Note: All parameters must be included in the request body for successful authentication.",

        requestBody: [
          { name: "consumerKey", type: "string", description: "Consumer key provided by Kotak" },
          { name: "consumerSecret", type: "string", description: "Consumer secret provided by Kotak" },
          { name: "mobileNumber", type: "string", description: "Registered mobile number" },
          { name: "password", type: "string", description: "User's Kotak trading password" },
          { name: "mpin", type: "string", description: "User's Kotak MPIN" }
        ],
        curl: `curl --location '/kotak/get-otp' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
    "consumerKey": "XxlilApOtMev2D_TONcl8uVDG5oa",
    "consumerSecret": "tCCOMofnJGG03BhmgmC_f9iIKvUa",
    "mobileNumber": "9999999943",
    "password": "Trading@123",
    "mpin": "1234"
}'`,
        payload: `{
    "consumerKey": "XxlilApOtMev2D_TONcl8uVDG5oa",
    "consumerSecret": "tCCOMofnJGG03BhmgmC_f9iIKvUa",
    "mobileNumber": "9999999943",
    "password": "Trading@123",
    "mpin": "1234"
}`,
        response: `{
    "access_token": "eyJ4NXQiOiJNbUprWWpVMlpETmpNelpqTURBM05UZ3pObUUxTm1NNU1qTXpNR1kyWm1OaFpHUTFNakE1TmciLCJraWQiOiJaalJqTUdRek9URmhPV1EwTm1WallXWTNZemRtWkdOa1pUUmpaVEUxTlRnMFkyWTBZVEUyTlRCaVlURTRNak5tWkRVeE5qZ3pPVGM0TWpGbFkyWXpOUV9SUzI1NiIsImFsZyI6IlJTMjU2In0...",
    "otp_response": {
        "data": {
            "email": "alp*******************@gmail.com",
            "greetingName": "ALPHAQUARK",
            "mobile": "***********43"
        }
    },
    "sid": "225c093f-40f6-45c1-a0bd-c263e82fd8fe",
    "view_token": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9..."
}`
      },



      upstox: {
        title: "Upstox Login",
        endpoint: "https://api.example.com/upstox/login-url",
        method: "POST",
        description: "Redirects user to Upstox's site for login.",
        note: "Note: All parameters must be included in the request body for successful authentication.",

        requestBody: [
          { name: "apiKey", type: "string", description: "Unique API key provided by the broker for authenticating API requests." },
          { name: "apiSession", type: "string", description: "Received as a URL parameter upon redirection after successful login on the broker's site." },
          { name: "redirectUri", type: "string", description: "The URL of your application to which the user will be redirected after successful authentication on the broker's site. This URL will receive the requestToken as a parameter for further API authorization." }
        ],
        curl: `curl --location '/upstox/login-url' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
    "apiKey": "d40a47c7-125c-4359-9dc3-66602565802",
    "apiSecret": "80jrvsfd987",
    "redirectUri": "enter_your_website"
}'`,
        payload: `{
    "apiKey": "d40a47c7-125c-4359-9dc3-66602565802",
    "apiSecret": "80jrvsfd987",
    "redirectUri": "enter_your_website"
}`,
        response: `"https://api.upstox.com/v2/login/authorization/dialog?response_type=code&client_id=d40a47c7-125c-4359-9dc3-66602565802&redirect_uri=your_website"`
      },
      upstoxAccessToken: {
        title: "Upstox AccessToken",
        endpoint: "https://api.example.com/upstox/gen-access-token",
        method: "POST",
        description: "Generates accessToken to be used for subsequent requests.",
        note: "Note: All parameters must be included in the request body for successful authentication.",

        requestBody: [
          { name: "apiKey", type: "string", description: "Unique API key provided by the broker for authenticating API requests." },
          { name: "apiSecret", type: "string", description: "Confidential key paired with the API key, used to sign requests and enhance security in broker authentication." },
          { name: "code", type: "string", description: "Received as a URL parameter upon redirection after successful login on the broker's site." },
          { name: "redirectUri", type: "string", description: "The URL of your application to which the user will be redirected after successful authentication on the broker's site. This URL will receive the requestToken as a parameter for further API authorization." },
        ],
        curl: `curl --location '/upstox/gen-access-token' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
    "apiKey": "d40a47c7-125c-4359-9dc3-66602565802",
    "apiSecret": "80jrvsfd987",
    "code": "kdsHQS",
    "redirectUri": "https://test.alphaquark-case.com/stock-recommendation"
}'`,
        payload: `{
    "apiKey": "d40a47c7-125c-4359-9dc3-66602565802",
    "apiSecret": "80jrvsfd987",
    "code": "kdsHQS",
    "redirectUri": "https://test.alphaquark-case.com/stock-recommendation"
}`,
        response: `{
    "access_token": "eyJ0eXAiOiJKV1QiLCFMyNTYifQ.eyJzMDkiLCJpc011bHRpCJleHAiOjE3MjAxMzA0MDB9.4v-187fwDOUn5ogVeOv_m_FFSYf0",
    "broker": "UPSTOX",
    "email": "example@gmail.com",
    "exchanges": ["NSE", "BSE"],
    "extended_token": null,
    "is_active": true,
    "order_types": ["MARKET", "LIMIT", "SL", "SL-M"],
    "poa": false,
    "products": ["OCO", "D", "CO", "I"],
    "user_id": "7GA8S7",
    "user_name": "example kumar",
    "user_type": "individual"
}`
      },
      zerodhaBroker: {
        title: "Zerodha Login",
        endpoint: "https://api.example.com/zerodha/login-url",
        method: "POST",
        description: "Redirects user to Zerodha's site for login.",
        note: "Note: All parameters must be included in the request body for successful authentication.",

        requestBody: [
          { name: "website", type: "string", description: "Your website where user will be redirected. After successful login a requestToken is passed in url." }
        ],
        curl: `curl --location '/zerodha/login-url' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
    "website": "enter_your_website"
}'`,
        payload: `{
    "website": "enter_your_website"
}`,
        response: `"https://kite.zerodha.com/connect/login?api_key=b0g1r806oitsamoe&v=3&redirect_params=website%3Dtrue"`
      },
      zerodhaAccessToken: {
        title: "Zerodha Token",
        endpoint: "https://api.example.com/zerodha/gen-access-token",
        method: "POST",
        description: "Generates accessToken to be used for subsequent requests.",
        note: "Note: All parameters must be included in the request body for successful authentication.",

        requestBody: [
          { name: "requestToken", type: "string", description: "Received as a URL parameter upon redirection after successful login on the broker's site." }
        ],
        curl: `curl --location '/zerodha/gen-access-token' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b7998' \\
--header 'Content-Type: application/json' \\
--data '{
    "requestToken": "Buuj4V8JDJSloMjalMK7Yykl4XfT5svD"
}'`,
        payload: `{
    "requestToken": "Buuj4V8JDJSloMjalMK7Yykl4XfT5svD"
}
`,
        response: `"{
    "access_token": "Pl3OTnxeYgHXlzKVd9Xlki6Ry8egT2uX",
    "api_key": "b0g1r806oitsamoe",
    "avatar_url": null,
    "broker": "ZERODHA",
    "email": "vandanamishra3106@gmail.com",
    "enctoken": "AnER0RRUiHlyAXNh09UC53i0ZLFpMBgmjL/n/2hZkYQVeFcpcRJyJ50G9ASB275N5m772czl3IuHwer1jfkDMsOj3TwjSgdTHA/oHm+8YEYwQULpHs+lRM7coZKmsTA=",
    "exchanges": [
        "NSE",
        "MF",
        "BSE"
    ],
    "login_time": "2024-11-11 14:02:50",
    "meta": {
        "demat_consent": "consent"
    },
    "order_types": [
        "MARKET",
        "LIMIT",
        "SL",
        "SL-M"
    ],
    "products": [
        "CNC",
        "NRML",
        "MIS",
        "BO",
        "CO"
    ],
    "public_token": "APV8fL0VGwSSqJXMm6y4esKc894OQTUr",
    "refresh_token": "",
    "user_id": "YZT300",
    "user_name": "Vandana Mishra",
    "user_shortname": "Vandana",
    "user_type": "individual/ind_with_nom"
}"`
      }
    }
  };

  const ErrorCodesSection = {
    title: "Error Codes",
    description: "Error codes and their meanings",
    content: (
      <div className="space-y-12 w-full max-w-full">
        {/* Section Header */}
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-2">Error Codes</h1>
          <p className="text-lg text-gray-600 dark:text-gray-400">
            Complete reference for all error codes and their meanings
          </p>
        </div>

        {/* AlphaQuark Error Codes */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden border border-gray-200 dark:border-gray-700 transition-all hover:shadow-xl">
          <div className="bg-gradient-to-r from-blue-500 to-blue-600 px-6 py-4">
            <h2 className="text-xl font-bold text-white">AlphaQuark</h2>
          </div>
          <div className="p-1 sm:p-3">
            <div className="overflow-x-auto rounded-lg">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-blue-50 dark:bg-blue-900/40">
                    <th className="px-6 py-3 text-left text-sm font-semibold text-blue-800 dark:text-blue-200 w-1/4">
                      Code
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-blue-800 dark:text-blue-200 w-1/4">
                      Message
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-blue-800 dark:text-blue-200 w-2/4">
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {errorCodes.alphaquark.map((error, index) => (
                    <tr
                      key={index}
                      className={`${index % 2 === 0 ? "bg-gray-50 dark:bg-gray-800" : "bg-white dark:bg-gray-900/20"
                        } hover:bg-blue-50 dark:hover:bg-blue-900/10 transition-colors`}
                    >
                      <td className="px-6 py-3 text-sm font-medium text-gray-700 dark:text-gray-300 w-1/4">
                        {error.code}
                      </td>
                      <td className="px-6 py-3 text-sm text-gray-700 dark:text-gray-300 w-1/4">
                        {error.message}
                      </td>
                      <td className="px-6 py-3 text-sm text-gray-600 dark:text-gray-400 w-2/4">
                        {error.description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Broker-Specific Error Codes */}
        <div className="grid grid-cols-1 gap-8">
          {["Angel One", "AliceBlue", "Dhan", "Fyers", "HDFC", "IIFL", "Upstox", "Zerodha"].map((broker) => (
            <div
              key={broker}
              className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden border border-gray-200 dark:border-gray-700 transition-all hover:shadow-xl"
            >
              <div className="bg-gradient-to-r from-blue-500 to-blue-600 px-6 py-4">
                <h2 className="text-xl font-bold text-white">{broker}</h2>
              </div>
              <div className="p-1 sm:p-3">
                <div className="overflow-x-auto rounded-lg">
                  <table className="w-full border-collapse">
                    <thead>
                      <tr className="bg-blue-50 dark:bg-blue-900/40">
                        <th className="px-6 py-3 text-left text-sm font-semibold text-blue-800 dark:text-blue-200 w-1/4">
                          Code
                        </th>
                        <th className="px-6 py-3 text-left text-sm font-semibold text-blue-800 dark:text-blue-200 w-1/4">
                          Message
                        </th>
                        <th className="px-6 py-3 text-left text-sm font-semibold text-blue-800 dark:text-blue-200 w-2/4">
                          Description
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {errorCodes.brokers
                        .filter((error) => error.broker === broker)
                        .map((error, index) => (
                          <tr
                            key={index}
                            className={`${index % 2 === 0 ? "bg-gray-50 dark:bg-gray-800" : "bg-white dark:bg-gray-900/20"
                              } hover:bg-blue-50 dark:hover:bg-blue-900/10 transition-colors`}
                          >
                            <td className="px-6 py-3 text-sm font-medium text-gray-700 dark:text-gray-300 w-1/4">
                              {error.code}
                            </td>
                            <td className="px-6 py-3 text-sm text-gray-700 dark:text-gray-300 w-1/4">
                              {error.message}
                            </td>
                            <td className="px-6 py-3 text-sm text-gray-600 dark:text-gray-400 w-2/4">
                              {error.description}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ),
  };
  const sections = {
    login: LoginSection,
    profile: {
      title: "Profile",
      endpoint: "https://api.example.com/{broker}/profile",
      method: "POST",
      description: `This fetches profile of user from broker. ${commonText.requestBodyIntro}`,
      note: "Note: All parameters listed are mandatory and must be included for successful API execution.",
      requestBody: Object.entries(brokerParams).map(([broker, params]) => ({
        name: `${broker}`,
        type: "string",
        description: params.description
      })),
      curl: `curl --location '/{broker}/profile' \\
${Object.entries(commonHeaders).map(([key, value]) => `--header '${key}: ${value}' \\`).join('\n')}
--data '{
  "apiKey": "EfkDdJMH",
  "accessToken": "Bearer eyJhZk8Q3euJhqrEcGAv0KxtnNiC456Ubz9Yw",
  "refreshToken": "kdfjkefie3242ioi34i3o4i343o4o34i34i34io3f"
}'`,
      payload: `{
  "apiKey": "EfkDdJMH",
  "accessToken": "Bearer eyJhZk8Q3euJhqrEcGAv0KxtnNiC456Ubz9Yw",
  "refreshToken": "kdfjkefie3242ioi34i3o4i343o4o34i34i34io3f"
}`,
      response: `{
  "data": {
    "broker": "",
    "clientcode": "V59512746",
    "email": "",
    "exchanges": ["nse_fo", "nse_cm", "cde_fo", "ncx_fo", "bse_fo", "bse_cm", "mcx_fo"],
    "lastlogintime": "",
    "mobileno": "",
    "name": "ABC Kumar",
    "products": ["MARGIN", "MIS", "NRML", "CNC", "CO", "BO"]
  },
  "errorcode": "",
  "message": "SUCCESS",
  "status": 0
}`
    },
    funds: {
      title: "Funds",
      endpoint: "https://api.example.com/{broker}/funds",
      method: "POST",
      description: `This fetches the user's fund details. ${commonText.requestBodyIntro}`,
      note: "Note: All parameters listed are mandatory and must be included for successful API execution.",

      requestBody: Object.entries(brokerParams).map(([broker, params]) => ({
        name: `${broker}`,
        type: "string",
        description: params.description
      })),
      curl: `curl --location '/{broker}/funds' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b799d67f05e9aeea37aec82a366d6cfd4ae18' \\
--header 'Content-Type: application/json' \\
--data '{
  "apiKey": "your_api_key",
  "accessToken": "your_jwt_token"
}'`,
      payload: `{
  "apiKey": "your_api_key",
  "accessToken": "your_jwt_token"
}`,
      response: `{
  "data": {
    "availablecash": "1000.0000",
    "availableintradaypayin": "0.0000",
    "availablelimitmargin": "0.0000",
    "collateral": "0.0000",
    "m2mrealized": "0.0000",
    "m2munrealized": "0.0000",
    "net": "1000.0000",
    "utiliseddebits": "0.0000",
    "utilisedexposure": null,
    "utilisedholdingsales": null,
    "utilisedoptionpremium": null,
    "utilisedpayout": "1000.0000",
    "utilisedspan": null,
    "utilisedturnover": null
  },
  "errorcode": "",
  "message": "SUCCESS",
  "status": true
}`
    },
    holdings: {
      title: "Holdings",
      endpoint: "https://api.example.com/{broker}/holdings",
      method: "POST",
      description: `Fetch the user's current holdings.${commonText.requestBodyIntro}`,
      note: "Note: All parameters listed are mandatory and must be included for successful API execution.",
      requestBody: Object.entries(brokerParams).map(([broker, params]) => ({
        name: `${broker}`,
        type: "string",
        description: params.description
      })),
      curl: `curl --location '/{broker}/holdings' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b799d67f05e9aeea37aec82a366d6cfd4ae18' \\
--header 'Content-Type: application/json' \\
--data '{
  "apiKey": "your_api_key",
  "accessToken": "your_jwt_token"
}'`,
      payload: `{
  "apiKey": "your_api_key",
  "accessToken": "your_jwt_token"
}`,
      response: `{
    "holding": [
        {
            "avgPrice": 7.81,
            "exchange": "NSE",
            "ltp": 7.54,
            "pnl": -6.0,
            "quantity": 23,
            "symbol": "DIL-EQ"
        },
        {
            "avgPrice": 16.31,
            "exchange": "NSE",
            "ltp": 16.58,
            "pnl": 1.0,
            "quantity": 5,
            "symbol": "IDEA-EQ"
        }
    ],
    "message": "SUCCESS",
    "status": 0
}`
    },
    allHoldings: {
      title: "All Holdings",
      endpoint: "https://api.example.com/{broker}/all-holdings",
      method: "POST",
      description: `Fetch all holdings for the user.${commonText.requestBodyIntro}`,
      note: "Note: All parameters listed are mandatory and must be included for successful API execution.",

      requestBody: Object.entries(brokerParams).map(([broker, params]) => ({
        name: `${broker}`,
        type: "string",
        description: params.description
      })),
      curl: `curl --location '/{broker}/all-holdings' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b799d67f05e9aeea37aec82a366d6cfd4ae18' \\
--header 'Content-Type: application/json' \\
--data '{
    "apiKey": "your_api_key",
    "accessToken": "your_jwt_token"
}'`,
      payload: `{
    "apiKey": "your_api_key",
    "accessToken": "your_jwt_token"
}`,
      response: `{
    "message": "SUCCESS",
    "status": 0,
    "totalHoldings": {
        "totalholdingvalue": 972,
        "totalinvvalue": 902,
        "totalpnlpercentage": 7.84,
        "totalprofitandloss": 70.69
    }
}`
    },
    placeOrder: {
      title: "Place Order",
      endpoint: "https://api.example.com/{broker}/place-order",
      method: "POST",
      description: `Place a new order in the market.${commonText.requestBodyIntro}`,
      note: "Note: All parameters listed are mandatory and must be included for successful API execution.",

      requestBody: Object.entries(brokerParams).map(([broker, params]) => ({
        name: `${broker}`,
        type: "string",
        description: params.description
      })),
      curl: `curl --location '/{broker}/place-order' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b799d67f05e9aeea37aec82a366d6cfd4ae18' \\
--header 'Content-Type: application/json' \\
--data '{
   "apiKey": "your_api_key",
   "accessToken": "your_jwt_token",
  "order": {
      "transactionType": "BUY",
      "exchange": "NSE",
      "segment": "EQUITY",
      "productType": "DELIVERY",
      "orderType": "MARKET",
      "price": 0,
      "tradingSymbol": "YESBANK",
      "quantity": 1
  }
}'`,
      payload: `{
   "apiKey": "your_api_key",
   "accessToken": "your_jwt_token",
  "order": {
      "transactionType": "BUY",
      "exchange": "NSE",
      "segment": "EQUITY",
      "productType": "DELIVERY",
      "orderType": "MARKET",
      "price": 0,
      "tradingSymbol": "YESBANK",
      "quantity": 1
  }
}`,
      response: `{
  "message": "SUCCESS",
  "orderId": "240603001929661",
  "status": 0,
  "symbol": "YESBANK-EQ",
  "uniqueOrderId": "a6d74f96-cfa6-4466-82a4-f2d126ef5cb4"
}`
    },
    modifyOrder: {
      title: "Modify Order",
      endpoint: "https://api.example.com/{broker}/modify-order",
      method: "POST",
      description: `Modify an existing order in the market.${commonText.requestBodyIntro}`,
      note: "Note: All parameters listed are mandatory and must be included for successful API execution.",

      requestBody: Object.entries(brokerParams).map(([broker, params]) => ({
        name: `${broker}`,
        type: "string",
        description: params.description
      })),
      curl: `curl --location '/{broker}/modify-order' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b799d67f05e9aeea37aec82a366d6cfd4ae18' \\
--header 'Content-Type: application/json' \\
--data '{
  "apiKey": "tVN954OO",
  "accessToken": "Bearer eyJhbGciOiJIUzR3-wcsbskA",
  "order": {
    "transactionType": "BUY",
    "exchange": "NSE",
    "segment": "EQUITY",
    "productType": "DELIVERY",
    "orderType": "LIMIT",
    "price": 15,
    "tradingSymbol": "IDEA",
    "quantity": 3
  },
  "orderId": "240715001144727"
}'`,
      payload: `{
  "apiKey": "tVN954OO",
  "accessToken": "Bearer eyJhbGciOiJIUzR3-wcsbskA",
  "order": {
    "transactionType": "BUY",
    "exchange": "NSE",
    "segment": "EQUITY",
    "productType": "DELIVERY",
    "orderType": "LIMIT",
    "price": 15,
    "tradingSymbol": "IDEA",
    "quantity": 3
  },
  "orderId": "240715001144727"
}`,
      response: `{
  "message": "SUCCESS",
  "orderId": "240603001929661",
  "status": 0,
  "symbol": "",
  "uniqueOrderId": "a6d74f96-cfa6-4466-82a4-f2d126ef5cb4"
}`
    },
    cancelOrder: {
      title: "Cancel Order",
      endpoint: "https://api.example.com/{broker}/cancel-order",
      method: "POST",
      description: `Cancel an existing order in the market.${commonText.requestBodyIntro}`,
      note: "Note: All parameters listed are mandatory and must be included for successful API execution.",

      requestBody: Object.entries(brokerParams).map(([broker, params]) => ({
        name: `${broker}`,
        type: "string",
        description: params.description
      })),
      curl: `curl --location '/{broker}/cancel-order' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b799d67f05e9aeea37aec82a366d6cfd4ae18' \\
--header 'Content-Type: application/json' \\
--data '{
  "apiKey": "tVN954OO",
  "accessToken": "Bearer PRE13Wk0T08iLCJ3-wcsbskA",
  "variety": "NORMAL",
  "orderId": "240715001144727"
}'`,
      payload: `{
  "apiKey": "tVN954OO",
  "accessToken": "Bearer PRE13Wk0T08iLCJ3-wcsbskA",
  "variety": "NORMAL",
  "orderId": "240715001144727"
}`,
      response: `{
    "message": "SUCCESS",
    "orderId": "240715001144727",
    "status": 0,
    "symbol": "",
    "uniqueOrderId": "71d8bc1a-406e-4947-b745-500d3855c830"
}`
    },
    positions: {
      title: "Positions",
      endpoint: "https://api.example.com/{broker}/positions",
      method: "POST",
      description: `Fetch the user's current positions.${commonText.requestBodyIntro}`,
      note: "Note: All parameters listed are mandatory and must be included for successful API execution.",

      requestBody: Object.entries(brokerParams).map(([broker, params]) => ({
        name: `${broker}`,
        type: "string",
        description: params.description
      })),
      curl: `curl --location '/{broker}/positions' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b799d67f05e9aeea37aec82a366d6cfd4ae18' \\
--header 'Content-Type: application/json' \\
--data '{
  "apiKey": "your_api_key",
  "accessToken": "your_jwt_token"
}'`,
      payload: `{
  "apiKey": "your_api_key",
  "accessToken": "your_jwt_token"
}`,
      response: `{
    "message": "SUCCESS",
    "position": [
        {
            "buyAmount": "15.10",
            "buyAvgPrice": "7.55",
            "buyQuantity": "2",
            "exchange": "NSE",
            "multiplier": "-1",
            "name": "DIL",
            "netQuantity": "2",
            "sellAmount": "0.00",
            "sellAvgPrice": "0.00",
            "sellQuantity": "0",
            "symbol": "DIL-EQ"
        }
    ],
    "status": 0
}`
    },
    orderBook: {
      title: "Order Book",
      endpoint: "https://api.example.com/{broker}/order-book",
      method: "POST",
      description: `Fetch the user's order book.${commonText.requestBodyIntro}`,
      note: "Note: All parameters listed are mandatory and must be included for successful API execution.",

      requestBody: Object.entries(brokerParams).map(([broker, params]) => ({
        name: `${broker}`,
        type: "string",
        description: params.description
      })),
      curl: `curl --location '/{broker}/order-book' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b799d67f05e9aeea37aec82a366d6cfd4ae18' \\
--header 'Content-Type: application/json' \\
--data '{
  "apiKey": "your_api_key",
  "accessToken": "your_jwt_token"
}'`,
      payload: `{
  "apiKey": "your_api_key",
  "accessToken": "your_jwt_token"
}`,
      response: `{
    "message": "SUCCESS",
    "orderBook": [
        {
            "averagePrice": 7.55,
            "disclosedQuantity": "0",
            "duration": "DAY",
            "exchange": "NSE",
            "filledShares": "2",
            "instrumentType": "",
            "lotsize": "1",
            "optionType": "",
            "orderId": "240715001117341",
            "orderStatus": "complete",
            "orderStatusMessage": "",
            "orderType": "MARKET",
            "orderUpdateTime": "15-Jul-2024 13:41:48",
            "price": 0.0,
            "productType": "DELIVERY",
            "quantity": "2",
            "symbol": "DIL-EQ",
            "transactionType": "BUY",
            "unfilledShares": "0",
            "uniqueOrderId": "dd6dfe75-dfb6-4783-a8f7-d6b2c27472af",
            "variety": "NORMAL"
        }
    ],
    "status": 0
}`
    },
    tradeBook: {
      title: "Trade Book",
      endpoint: "https://api.example.com/{broker}/trade-book",
      method: "POST",
      description: `Fetch the user's trade book.${commonText.requestBodyIntro}`,
      note: "Note: All parameters listed are mandatory and must be included for successful API execution.",

      requestBody: Object.entries(brokerParams).map(([broker, params]) => ({
        name: `${broker}`,
        type: "string",
        description: params.description
      })),
      curl: `curl --location '/{broker}/trade-book' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b799d67f05e9aeea37aec82a366d6cfd4ae18' \\
--header 'Content-Type: application/json' \\
--data '{
  "apiKey": "your_api_key",
  "accessToken": "your_jwt_token"
}'`,
      payload: `{
  "apiKey": "your_api_key",
  "accessToken": "your_jwt_token"
}`,
      response: `{
    "message": "SUCCESS",
    "status": 0,
    "tradeBook": [
        {
            "exchange": "NSE",
            "filledShares": "2",
            "multiplier": "1",
            "orderId": "240715001117341",
            "price": 7.55,
            "productType": "DELIVERY",
            "quantity": null,
            "symbol": "DIL-EQ",
            "transactionType": "BUY",
            "unfilledShares": null
        }
    ]
}`
    },
    singleOrderStatus: {
      title: "Single Order Status",
      endpoint: "https://api.example.com/{broker}/single-order-status",
      method: "POST",
      description: `Fetch the status of a single order.${commonText.requestBodyIntro}`,
      note: "Note: All parameters listed are mandatory and must be included for successful API execution.",

      requestBody: Object.entries(brokerParams).map(([broker, params]) => ({
        name: `${broker}`,
        type: "string",
        description: params.description
      })),
      curl: `curl --location '/{broker}/single-order-status' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b799d67f05e9aeea37aec82a366d6cfd4ae18' \\
--header 'Content-Type: application/json' \\
--data '{
  "apiKey": "your_api_key",
  "accessToken": "your_jwt_token",
  "uniqueOrderId": "188c0144-dd1d-4b95-82a1-05846b68abc"
}'`,
      payload: `{
  "apiKey": "your_api_key",
  "accessToken": "your_jwt_token",
  "uniqueOrderId": "188c0144-dd1d-4b95-82a1-05846b68abc"
}`,
      response: `{
  "averagePrice": 0.0,
  "disclosedQuantity": "0",
  "duration": "DAY",
  "exchange": "NSE",
  "filledShares": "0",
  "instrumentType": "",
  "lotsize": "1",
  "message": "SUCCESS",
  "optionType": "",
  "orderId": "240603001929661",
  "orderStatus": "rejected",
  "orderStatusMessage": "The connection to the exchange server is currently unavailable.",
  "orderType": "MARKET",
  "orderUpdateTime": "",
  "price": 0.0,
  "productType": "DELIVERY",
  "quantity": "1",
  "status": 0,
  "symbol": "YESBANK-EQ",
  "transactionType": "BUY",
  "unfilledShares": "1",
  "uniqueOrderId": "a6d74f96-cfa6-4466-82a4-f2d126ef5cb4",
  "variety": "NORMAL"
}`
    },
    orderStatuses: {
      title: "Order Statuses",
      endpoint: "https://api.example.com/{broker}/order-statuses",
      method: "POST",
      description: `Fetch the status of multiple orders.${commonText.requestBodyIntro}`,
      note: "Note: All parameters listed are mandatory and must be included for successful API execution.",

      requestBody: Object.entries(brokerParams).map(([broker, params]) => ({
        name: `${broker}`,
        type: "string",
        description: params.description
      })),
      curl: `curl --location '/{broker}/order-statuses' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b799d67f05e9aeea37aec82a366d6cfd4ae18' \\
--header 'Content-Type: application/json' \\
--data '{
  "apiKey": "tVN954OO",
  "accessToken": "Bearer eyJhbGciOiJIUzUx",
  "uniqueOrderIds": ["dd6dfe75-dfb6-4783-a8f7-d6b2c27472af", "71d8bc1a-406e-4947-b745-500d3855c830"]
}'`,
      payload: `{
  "apiKey": "tVN954OO",
  "accessToken": "Bearer eyJhbGciOiJIUzUx",
  "uniqueOrderIds": ["dd6dfe75-dfb6-4783-a8f7-d6b2c27472af", "71d8bc1a-406e-4947-b745-500d3855c830"]
}`,
      response: `[
    {
        "averagePrice": 7.55,
        "disclosedQuantity": "0",
        "duration": "DAY",
        "exchange": "NSE",
        "filledShares": "2",
        "instrumentType": "",
        "lotsize": "1",
        "message": "SUCCESS",
        "optionType": "",
        "orderId": "240715001117341",
        "orderStatus": "complete",
        "orderStatusMessage": "",
        "orderType": "MARKET",
        "orderUpdateTime": "15-Jul-2024 13:41:48",
        "price": 0.0,
        "productType": "DELIVERY",
        "quantity": "2",
        "status": 0,
        "symbol": "DIL-EQ",
        "transactionType": "BUY",
        "unfilledShares": "0",
        "uniqueOrderId": "dd6dfe75-dfb6-4783-a8f7-d6b2c27472af",
        "variety": "NORMAL"
    },
    {
        "averagePrice": 0.0,
        "disclosedQuantity": "0",
        "duration": "DAY",
        "exchange": "NSE",
        "filledShares": "0",
        "instrumentType": "",
        "lotsize": "1",
        "message": "SUCCESS",
        "optionType": "",
        "orderId": "240715001144727",
        "orderStatus": "cancelled",
        "orderStatusMessage": "",
        "orderType": "LIMIT",
        "orderUpdateTime": "15-Jul-2024 14:07:12",
        "price": 15.0,
        "productType": "DELIVERY",
        "quantity": "3",
        "status": 0,
        "symbol": "IDEA-EQ",
        "transactionType": "BUY",
        "unfilledShares": "3",
        "uniqueOrderId": "71d8bc1a-406e-4947-b745-500d3855c830",
        "variety": "NORMAL"
    }
]`
    },
    processTrades: {
      title: "Process Trades",
      endpoint: "https://api.example.com/{broker}/process-trades",
      method: "POST",
      description: `Process multiple trades for a user.${commonText.requestBodyIntro}`,
      note: "Note: All parameters listed are mandatory and must be included for successful API execution.",

      requestBody: Object.entries(brokerParams).map(([broker, params]) => ({
        name: `${broker}`,
        type: "string",
        description: params.description
      })),

      optional: {
        'user_email': '',
        'priority': '',
        'trade_given_by': '',
        'user_broker': '',
        'tradeId': ''
    },
      
      curl: `curl --location '/{broker}/process-trades' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b799d67f05e9aeea37aec82a366d6cfd4ae18' \\
--header 'Content-Type: application/json' \\
--data '{
  "apiKey": "your_api_key",
  "accessToken": "your_jwt_token",
  "trades": [
    {
      "user_email": "2015ravimishra@gmail.com",
      "transactionType": "SELL",
      "exchange": "NSE",
      "segment": "EQUITY",
      "productType": "DELIVERY",
      "orderType": "MARKET",
      "price": 0,
      "tradingSymbol": "IDFC",
      "quantity": 1,
      "priority": 0,
      "trade_given_by": "advisor@company.com",
      "user_broker": "Angel One",
      "tradeId": "12456"
    },
    {
      "user_email": "2015ravimishra@gmail.com",
      "transactionType": "BUY",
      "exchange": "NSE",
      "segment": "EQUITY",
      "productType": "DELIVERY",
      "orderType": "MARKET",
      "price": 0,
      "tradingSymbol": "NIF100BEES",
      "quantity": 1,
      "priority": 0,
      "trade_given_by": "advisor@company.com",
      "user_broker": "Angel One",
      "tradeId": "654321"
    }
  ]
}'`,
      payload: `{
  "apiKey": "your_api_key",
  "accessToken": "your_jwt_token",
  "trades": [
    {
      "user_email": "2015ravimishra@gmail.com",
      "transactionType": "SELL",
      "exchange": "NSE",
      "segment": "EQUITY",
      "productType": "DELIVERY",
      "orderType": "MARKET",
      "price": 0,
      "tradingSymbol": "IDFC",
      "quantity": 1,
      "priority": 0,
      "trade_given_by": "advisor@company.com",
      "user_broker": "Angel One",
      "tradeId": "12456"
    },
    {
      "user_email": "2015ravimishra@gmail.com",
      "transactionType": "BUY",
      "exchange": "NSE",
      "segment": "EQUITY",
      "productType": "DELIVERY",
      "orderType": "MARKET",
      "price": 0,
      "tradingSymbol": "NIF100BEES",
      "quantity": 1,
      "priority": 0,
      "trade_given_by": "advisor@company.com",
      "user_broker": "Angel One",
      "tradeId": "654321"
    }
  ]
}`,
      response: `
      {
  "status": 0,
  "order_details": [
    {
      "averagePrice": 0.0,
      "disclosedQuantity": "0",
      "duration": "DAY",
      "exchange": "NSE",
      "filledShares": "0",
      "instrumentType": "",
      "lotsize": "1",
      "message": "SUCCESS",
      "optionType": "",
      "orderId": "240604000007187",
      "orderStatus": "rejected",
      "orderStatusMessage": "The connection to the exchange server is currently unavailable.",
      "orderType": "MARKET",
      "orderUpdateTime": "",
      "price": 0.0,
      "productType": "DELIVERY",
      "quantity": "1",
      "status": 0,
      "symbol": "IDFC-EQ",
      "transactionType": "SELL",
      "unfilledShares": "1",
      "uniqueOrderId": "5c48fe3d-36e1-4503-943c-f4f403f0c752",
      "variety": "NORMAL"
    },
      {
      {
        "advice_status": "rejected",
        "order_details": {
    {
        "advice_status": "rejected",
        "order_details": {
      "averagePrice": 0.0,
      "disclosedQuantity": "0",
      "duration": "DAY",
      "exchange": "NSE",
      "filledShares": "0",
      "instrumentType": "",
      "lotsize": "1",
      "message": "SUCCESS",
      "optionType": "",
      "orderId": "240604000007188",
      "orderStatus": "rejected",
      "orderStatusMessage": "The connection to the exchange server is currently unavailable.",
      "orderType": "MARKET",
      "orderUpdateTime": "",
      "price": 0.0,
      "productType": "DELIVERY",
      "quantity": "1",
      "status": 0,
      "symbol": "NIF100BEES-EQ",
      "transactionType": "BUY",
      "unfilledShares": "1",
      "uniqueOrderId": "a28457b9-9ac7-4455-bb1f-542eac486841",
      "variety": "NORMAL"
    }
  ]
}`
    },
    marketData: {
      title: "Market Data",
      endpoint: "https://api.example.com/{broker}/market-data",
      method: "POST",
      description: "Fetch market data for specified symbols.",
      note: "Note: All parameters listed are mandatory and must be included for successful API execution.",

      requestBody: [
        { name: "Orders", type: "array", description: "An array of objects containing exchange, segment, and tradingSymbol for each stock." }
      ],
      curl: `curl --location '/{broker}/market-data' \\
--header 'aq-api-key: 12345' \\
--header 'aq-signature: f0e18e59b1921529c3856ddb1e6b799d67f05e9aeea37aec82a366d6cfd4ae18' \\
--header 'Content-Type: application/json' \\
--data '{
    "Orders": [
        {
            "exchange": "NSE",
            "segment": "EQUITY",
            "tradingSymbol": "NTPC"
        },
        {
            "exchange": "NSE",
            "segment": "EQUITY",
            "tradingSymbol": "RELIANCE"
        },
        {
            "exchange": "NSE",
            "segment": "EQUITY",
            "tradingSymbol": "INFY"
        }
    ]
}'`,
      payload: `{
    "Orders": [
        {
            "exchange": "NSE",
            "segment": "EQUITY",
            "tradingSymbol": "NTPC"
        },
        {
            "exchange": "NSE",
            "segment": "EQUITY",
            "tradingSymbol": "RELIANCE"
        },
        {
            "exchange": "NSE",
            "segment": "EQUITY",
            "tradingSymbol": "INFY"
        }
    ]
}`,
      response: `{
    "data": {
        "fetched": [
            {
                "exchange": "NSE",
                "ltp": 2962.05,
                "symbolToken": "2885",
                "tradingSymbol": "RELIANCE-EQ"
            },
            {
                "exchange": "NSE",
                "ltp": 1498.2,
                "symbolToken": "1594",
                "tradingSymbol": "INFY-EQ"
            },
            {
                "exchange": "NSE",
                "ltp": 369.65,
                "symbolToken": "11630",
                "tradingSymbol": "NTPC-EQ"
            }
        ],
        "unfetched": []
    },
    "errorcode": "",
    "message": "SUCCESS",
    "status": true
}`
    },
    modelPf: modelPfSection,
    errorCodes: ErrorCodesSection,
  };

  const getActiveSection = () => {
    if (!activeSection) return null;
    if (activeSection.includes('.')) {
      const [main, sub] = activeSection.split('.');
      return sections[main].subsections[sub];
    }
    return sections[activeSection];
  };
  const getParamDescription = (broker, section) => {
    // If section specific description exists, return it
    if (section && sectionDescriptions[section]?.[broker]) {
      return sectionDescriptions[section][broker];
    }
    // Fallback to default description from brokerParams
    return brokerParams[broker]?.description || '';
  };
  const [selectedBroker, setSelectedBroker] = useState(null);
  const isLoginSection = activeSection?.toLowerCase().includes('login');


  return (
    <div className={`flex flex-col md:flex-row h-screen ${darkMode ? 'dark' : ''}`}>
      {/* Top Bar */}
      <div className="fixed top-0 left-0 right-0 h-16 bg-white dark:bg-gray-900 shadow-md z-50 flex items-center justify-between px-4">
        <button
          className="md:hidden bg-blue-500 dark:bg-blue-600 text-white p-2 rounded-full"
          onClick={toggleMenu}
        >
          {menuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
        <h1 className="text-xl font-bold text-blue-600 dark:text-blue-400">API Documentation</h1>
        <button
          className="bg-blue-500 dark:bg-blue-600 text-white p-2 rounded-full"
          onClick={toggleDarkMode}
        >
          {darkMode ? <Sun size={24} /> : <Moon size={24} />}
        </button>
      </div>

      {/* Left Menu */}
      <div className={`${menuOpen ? 'fixed inset-0 z-40 bg-white dark:bg-gray-800' : 'hidden'
        } md:relative md:block md:w-64 bg-white dark:bg-gray-800 shadow-md p-4 overflow-y-auto border-r border-gray-200 dark:border-gray-700 mt-16`}>
        <button
          onClick={goToHome}
          className="text-xl font-bold mb-4 text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 transition-colors duration-200 w-full text-left"
        >
          API Endpoints
        </button>
        <ul className="space-y-2">
          {Object.entries(sections).map(([key, section]) => (
            <li key={key}>
              {section.subsections ? (
                <div>
                  <button
                    onClick={() => setActiveSection(key)}
                    className={`w-full text-left p-2 rounded text-sm ${activeSection?.startsWith(key) ? 'bg-blue-500 text-white dark:bg-blue-600' : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-200'
                      }`}
                  >
                    {section.title}
                  </button>
                  <ul className="ml-4 mt-2 space-y-1">
                    {Object.entries(section.subsections).map(([subKey, subSection]) => (
                      <li key={subKey}>
                        <button
                          onClick={() => {
                            setActiveSection(`${key}.${subKey}`);
                            setMenuOpen(false);
                          }}
                          className={`w-full text-left p-1 rounded text-xs ${activeSection === `${key}.${subKey}` ? 'bg-blue-300 text-white dark:bg-blue-500' : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-200'
                            }`}
                        >
                          {subSection.title}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <button
                  onClick={() => {
                    setActiveSection(key);
                    setMenuOpen(false);
                  }}
                  className={`w-full text-left p-2 rounded text-sm ${activeSection === key ? 'bg-blue-500 text-white dark:bg-blue-600' : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-200'
                    }`}
                >
                  {section.title}
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col md:flex-row overflow-hidden pt-16 md:pt-8">
        {/* Center Content */}
        <div className={`w-full ${activeSection === 'errorCodes' ? 'md:w-full' : 'md:w-1/2'} p-4 md:p-8 overflow-y-auto bg-white dark:bg-gray-800`}>
          {activeSection ? (
            <>
              <h1 className="text-2xl md:text-3xl font-bold mb-8 mt-4 text-blue-600 dark:text-blue-400 border-b pb-4 border-gray-200 dark:border-gray-700">
                {getActiveSection()?.title || sections[activeSection]?.title}
              </h1>
              <div className="space-y-6">
                {activeSection === 'login' || activeSection === 'modelPf' ? (
                  <div className="bg-blue-50 dark:bg-gray-700 p-4 rounded-lg border border-blue-200 dark:border-gray-600">
                    <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600 dark:text-blue-400">
                      Overview
                    </h2>
                    <p className="text-gray-700 dark:text-gray-300 mb-4">
                      {sections[activeSection].description}
                    </p>
                    <div className="text-gray-700 dark:text-gray-300" dangerouslySetInnerHTML={{ __html: sections[activeSection].content }} />
                  </div>
                ) : getActiveSection()?.endpoint ? (
                  <>
                    <div className="bg-blue-50 dark:bg-gray-700 p-4 rounded-lg border border-blue-200 dark:border-gray-600">
                      <h2 className="text-lg md:text-xl font-semibold mb-2 flex items-center text-blue-600 dark:text-blue-400">
                        <Globe className="mr-2 text-blue-500 dark:text-blue-400" />
                        Endpoint
                      </h2>
                      <p className="text-gray-700 dark:text-gray-300 flex items-center">
                        <ArrowRight className="mr-2 text-blue-500 dark:text-blue-400" />
                        {getActiveSection()?.endpoint}
                      </p>
                    </div>
                    <div className="bg-green-50 dark:bg-gray-700 p-4 rounded-lg border border-green-200 dark:border-gray-600">
                      <h2 className="text-lg md:text-xl font-semibold mb-2 flex items-center text-green-600 dark:text-green-400">
                        <Code className="mr-2 text-green-500 dark:text-green-400" />
                        Method
                      </h2>
                      <p className="text-gray-700 dark:text-gray-300 font-mono bg-green-100 dark:bg-gray-600 px-2 py-1 rounded inline-block">
                        {getActiveSection()?.method}
                      </p>
                    </div>
                    <div className="bg-purple-50 dark:bg-gray-700 p-4 rounded-lg border border-purple-200 dark:border-gray-600">
                      <h2 className="text-lg md:text-xl font-semibold mb-2 flex items-center text-purple-600 dark:text-purple-400">
                        <BookOpen className="mr-2 text-purple-500 dark:text-purple-400" />
                        Description
                      </h2>
                      <p className="text-gray-700 dark:text-gray-300 leading-relaxed mb-4">
                        {getActiveSection()?.description}
                      </p>
                      {getActiveSection()?.note && (
                        <div className="bg-yellow-50 dark:bg-yellow-900/20 border-l-4 border-yellow-400 p-2 mb-2">
                          <p className="text-yellow-700 dark:text-yellow-300 text-sm">
                            {getActiveSection()?.note}
                          </p>
                        </div>
                      )}
                      <h3 className="text-base md:text-lg font-semibold mb-2 flex items-center text-purple-600 dark:text-purple-400">
                        <Package className="mr-2 text-purple-500 dark:text-purple-400" />
                        Request Body
                      </h3>
                      {/* <ul className="list-disc list-inside space-y-2 mb-4 text-gray-700 dark:text-gray-300">
                        {getActiveSection()?.requestBody?.map((param, index) => (
                          <li key={index}>
                            <span className="font-semibold">{param.name}</span> ({param.type}): {param.description}
                          </li>
                        )) || <li>No request body parameters</li>}
                      </ul> */}
                      <div className="overflow-x-auto mb-4">
                        <table className="min-w-full table-auto border-collapse">
                          <thead>
                            <tr >
                              <th className="px-4 py-2 text-left border ">Broker</th>
                              <th className="px-4 py-2 text-left border ">Type</th>
                              <th className="px-4 py-2 text-left border ">Description</th>
                            </tr>
                          </thead>
                          <tbody className="text-gray-700 dark:text-gray-300">
                            {isLoginSection ? (
                              // For login section - show parameters individually
                              getActiveSection()?.requestBody?.map((param, index) => (
                                <tr key={index} className="border-b dark:border-gray-600">
                                  <td className="w-[30%] px-2 py-2 border dark:border-gray-600 font-semibold">
                                    {param.name}
                                  </td>
                                  <td className="w-[20%] px-2 py-2 border dark:border-gray-600 font-semibold">
                                    {param.type}
                                  </td>
                                  <td className="w-[50%] px-2 py-2 border dark:border-gray-600">
                                    {param.description}
                                  </td>
                                </tr>
                              ))
                            ) : (

                              // For other sections - show all brokers
                              Object.entries(brokerParams).map(([broker, params]) => (

                                <tr key={broker} className="border-b dark:border-gray-600">
                                  <td className="px-4 py-2 border dark:border-gray-600 font-semibold">{broker}</td>
                                  <td className="px-4 py-2 border dark:border-gray-600 font-semibold">
                                    <span>String</span>
                                  </td>
                                  <td className="px-4 py-2 border dark:border-gray-600"
                                    dangerouslySetInnerHTML={{
                                      __html: (activeSection && sectionDescriptions[activeSection]?.[broker])
                                        ? sectionDescriptions[activeSection][broker].description.replace(
                                          /<b>(.*?)<\/b>/g,
                                          '<span class="font-semibold">$1</span>'
                                        )
                                        : params.description.replace(
                                          /<b>(.*?)<\/b>/g,
                                          '<span class="font-semibold">$1</span>'
                                        )
                                    }}
                                  />
                                </tr>
                              ))
                            )}

                          </tbody>
                        </table>
                      </div>
                      {getActiveSection()?.optional && (
  <div className="mb-3 pb-2 border-b dark:border-gray-700">
    <h3 className="text-base md:text-lg font-semibold mb-2 flex items-center text-blue-500 dark:text-blue-400">
      <ListChecks className="mr-2 text-blue-500 dark:text-blue-400" />
      Optional Parameters
    </h3>
    <div className="font-semibold text-md text-gray-700 dark:text-gray-300">
      {Object.keys(getActiveSection().optional).join(', ')}
    </div>
  </div>
)}
{getActiveSection()?.title === "Process Trades" && (
  <div className="mb-3 pb-2 border-b dark:border-gray-700">
    <div className="text-md text-gray-700 dark:text-gray-300">
      <span className='text-md font-semibold'>priority:</span> When submitting multiple trades in a single payload, the priority parameter defines the order of execution for those trades.
    </div>
  </div>
)}   

<h3 className="text-base md:text-lg font-semibold mb-2 flex items-center text-purple-600 dark:text-purple-400">
                        <Terminal className="mr-2 text-purple-500 dark:text-purple-400" />
                        CURL Example
                      </h3>
                      <CodeBlock code={getActiveSection()?.curl} language="bash" />
                    </div>
                  </>
                ) : (
                  <div className="bg-blue-50 dark:bg-gray-700 p-4 rounded-lg border border-blue-200 dark:border-gray-600">
                    <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600 dark:text-blue-400">
                      Overview
                    </h2>
                    <p className="text-gray-700 dark:text-gray-300">
                      {sections[activeSection]?.description}
                    </p>
                    <div className="mt-4">
                      {sections[activeSection]?.content}
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center justify-center h-full text-center">
              <h1 className="text-3xl md:text-4xl font-bold text-blue-600 dark:text-blue-400 mb-4">Welcome to
                AlphaQuark API Documentation</h1>
              <p className="text-lg md:text-xl text-gray-600 dark:text-gray-300 max-w-md">
                Explore our comprehensive API documentation to integrate powerful trading features into your
                applications.
              </p>
            </div>
          )}
        </div>

        {/* Right Side - Payload and Response */}
        {activeSection !== 'errorCodes' && (
          <div className="w-full md:w-1/2 bg-gray-100 dark:bg-gray-700 p-4 md:p-6 overflow-y-auto">
            {activeSection && getActiveSection()?.payload ? (
              <>
                <div className="mb-6 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
                  <div className="flex items-center mb-2">
                    <Code className="mr-2 text-blue-500 dark:text-blue-400" />
                    <h3 className="text-base md:text-lg font-semibold text-blue-600 dark:text-blue-400">Payload</h3>
                  </div>
                  <CodeBlock code={getActiveSection()?.payload} language="json" />
                </div>
                <div>
                  <div className="flex items-center mb-2">
                    <Code className="mr-2 text-blue-500 dark:text-blue-400" />
                    <h3 className="text-base md:text-lg font-semibold text-blue-600 dark:text-blue-400">Response</h3>
                  </div>
                  <CodeBlock code={getActiveSection()?.response} language="json" />
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center h-full text-center bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
                <ChevronRight className="w-12 h-12 md:w-16 md:h-16 text-blue-500 dark:text-blue-400 mb-4" />
                <h2 className="text-xl md:text-2xl font-bold text-gray-800 dark:text-gray-200 mb-2">Ready to
                  Explore?</h2>
                <p className="text-base md:text-lg text-gray-600 dark:text-gray-400">
                  {activeSection
                    ? "Select a specific login method to view its details, payload, and response."
                    : "Please select an API endpoint from the menu to view its details, payload, and response."}
                </p>
              </div>
            )}
          </div>
        )
        }
      </div>
    </div>
  );
};

export default DocumentationLayout;