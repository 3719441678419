export const errorCodes = {
    alphaquark: [
      {
        code: "0",
        message: "Success",
        description: "Request processed successfully"
      },
      {
        code: "1",
        message: "Invalid Request",
        description: "Request parameters are missing or invalid"
      },
      {
        code: "2",
        message: "Request Failed",
        description: "AQ server/end point error"
      }
     
    ],
    brokers: [
       // AliceBlue
       {
        code: "602",
        broker: "AliceBlue",
        message: "Trigger price not in multiples of tick size",
        description: "The trigger price provided does not match the required tick size multiples",
      },
      {
        code: "ADAPTER",
        broker: "AliceBlue",
        message: "ADAPTER is down",
        description: "Order placed before market hours (before 9:00 AM) will be rejected",
      },
      {
        code: "BO_AUTO_SQUARE",
        broker: "AliceBlue",
        message: "Auto square off executed",
        description: "Pre-market not allowed for BO order or intraday square off pre auto square off blocked",
      },
      {
        code: "BO_SELF_TRADE",
        broker: "AliceBlue",
        message: "Self Trade Rejection",
        description: "The exchange will match the order based on the PAN number",
      },
      {
        code: "3502",
        broker: "AliceBlue",
        message: "API Setup Issue",
        description: "Issue with Antplus API setup in APIBridge",
      },
      {
        code: "Not_Ok",
        broker: "AliceBlue",
        message: "Data not available",
        description: "Data not available at market time",
      },
      {
        code: "Session_Expired",
        broker: "AliceBlue",
        message: "Session Expired",
        description: "Data not available at market time",
      },
        // Angel One Authentication Errors
        {
          code: "AG8001",
          broker: "Angel One",
          message: "Invalid Token",
          description: "Authentication token provided is not valid"
        },
        {
          code: "AG8002",
          broker: "Angel One",
          message: "Token Expired", 
          description: "Authentication token has expired"
        },
        {
          code: "AG8003",
          broker: "Angel One",
          message: "Token Missing",
          description: "Authentication token was not provided"
        },
        {
          code: "AB8050",
          broker: "Angel One",
          message: "Invalid Refresh Token",
          description: "Refresh token provided is not valid"
        },
        {
          code: "AB8051",
          broker: "Angel One",
          message: "Refresh Token Expired",
          description: "Refresh token has expired"
        },
        // Angel One Trading Errors
        {
          code: "AB1008",
          broker: "Angel One",
          message: "Invalid Order Variety",
          description: "Order variety is not supported"
        },
        {
          code: "AB1012",
          broker: "Angel One",
          message: "Invalid Product Type",
          description: "Product type is not supported"
        },
        {
          code: "AB1013",
          broker: "Angel One",
          message: "Order Not Found",
          description: "Specified order does not exist"
        },
        {
          code: "AB1006",
          broker: "Angel One",
          message: "Trading Blocked",
          description: "Client is blocked for trading"
        },
        {
          code: "AB2002",
          broker: "Angel One",
          message: "ROBO Order Blocked",
          description: "Automated trading is blocked"
        },
         // Dhan
         {
          code: "DH-901",
          broker: "Dhan",
          message: "Invalid Authentication",
          description: "Client ID or user generated access token is invalid or expired.",
        },
        {
          code: "DH-902",
          broker: "Dhan",
          message: "Invalid Access",
          description:
            "User has not subscribed to Data APIs or does not have access to Trading APIs. Kindly subscribe to Data APIs to be able to fetch Data.",
        },
        {
          code: "DH-903",
          broker: "Dhan",
          message: "User Account",
          description:
            "Errors related to User's Account. Check if the required segments are activated or other requirements are met.",
        },
        {
          code: "DH-904",
          broker: "Dhan",
          message: "Rate Limit",
          description: "Too many requests on server from single user breaching rate limits. Try throttling API calls.",
        },
        {
          code: "DH-905",
          broker: "Dhan",
          message: "Input Exception",
          description: "Missing required fields, bad values for parameters etc.",
        },
        {
          code: "DH-906",
          broker: "Dhan",
          message: "Order Error",
          description: "Incorrect request for order and cannot be processed.",
        },
        {
          code: "DH-907",
          broker: "Dhan",
          message: "Data Error",
          description: "System is unable to fetch data due to incorrect parameters or no data present.",
        },
        {
          code: "DH-908",
          broker: "Dhan",
          message: "Internal Server Error",
          description: "Server was not able to process API request. This will only occur rarely.",
        },
        {
          code: "DH-909",
          broker: "Dhan",
          message: "Network Error",
          description: "Network error where the API was unable to communicate with the backend system.",
        },
        {
          code: "DH-910",
          broker: "Dhan",
          message: "Others",
          description: "Error originating from other reasons.",
        },
        {
          code: "800",
          broker: "Dhan",
          message: "Internal Server Error",
          description: "Internal server error occurred while processing the request.",
        },
        {
          code: "804",
          broker: "Dhan",
          message: "Request Limit Exceeded",
          description: "Requested number of instruments exceeds limit",
        },
        {
          code: "805",
          broker: "Dhan",
          message: "Too Many Requests",
          description: "Too many requests or connections. Further requests may result in the user being blocked.",
        },
        {
          code: "806",
          broker: "Dhan",
          message: "Subscription Required",
          description: "Data APIs not subscribed",
        },
        {
          code: "807",
          broker: "Dhan",
          message: "Token Expired",
          description: "Access token is expired",
        },
        {
          code: "808",
          broker: "Dhan",
          message: "Authentication Failed",
          description: "Authentication Failed - Client ID or Access Token invalid",
        },
        {
          code: "809",
          broker: "Dhan",
          message: "Invalid Token",
          description: "Access token is invalid",
        },
        {
          code: "810",
          broker: "Dhan",
          message: "Invalid Client",
          description: "Client ID is invalid",
        },
        
     // Fyers
     {
      code: "-209",
      broker: "Fyers",
      message: "Please provide a valid order number",
      description:
        "This error specifies that the order_id passed is either incorrect or not a valid one for the particular order",
    },
    {
      code: "-101",
      broker: "Fyers",
      message: "Please provide a valid order type",
      description: "This error specifes that the Type provided for the particular order is not a valid Type",
    },
    {
      code: "-305",
      broker: "Fyers",
      message: "Only market and limit orders are allowed",
      description: "This error specifies that for CO orders only market and limit orders are allowed",
    },
    {
      code: "-323",
      broker: "Fyers",
      message: "Only market and limit orders are allowed",
      description: "This error specifies that for BO orders only market and limit orders are allowed",
    },
    {
      code: "-308",
      broker: "Fyers",
      message: "Please provide a valid limitPrice",
      description: "This error specifies that a valid LimitPrice value needs to be passed for the particular symbol",
    },
    {
      code: "-309",
      broker: "Fyers",
      message: "Please provide a valid stop price",
      description: "This error specifies that a valid stopPrice value needs to be passed for the particular symbol",
    },
    {
      code: "-310",
      broker: "Fyers",
      message: "Please provide a valid order quantity",
      description:
        "This error specifies that the order Qty provided for the symbol is incorrect and need to provide a proper Qty",
    },
    {
      code: "-311",
      broker: "Fyers",
      message: "Please provide a valid stop loss price",
      description: "This error specifies that the Stoploss price provided is incorrect for the specified symbol",
    },
    {
      code: "-313",
      broker: "Fyers",
      message: "Please provide a valid order side of either buy or sell",
      description: "This error specifies that a valid side either (buy/sell) wasn't send for the symbol",
    },
    {
      code: "-314",
      broker: "Fyers",
      message: "Please provide a valid product type for the order",
      description: "This error specifies the Type(Intraday/CNC/Margin) of the symbol was incorrect",
    },
    {
      code: "-315",
      broker: "Fyers",
      message: "Please provide a valid price for stop limit order",
      description: "This error specifies that the for the StopLimit order provide a valid stopPrice",
    },
    {
      code: "-316",
      broker: "Fyers",
      message: "Please provide a valid stop loss price",
      description: "This error specifies that for the CO and BO orders the stopLoss key Provided is Incorrect",
    },
    {
      code: "-323",
      broker: "Fyers",
      message: "Please provide a valid stop loss value",
      description: "This error specifies that the Stoploss provided is incorrect for the particular order",
    },
    {
      code: "-325",
      broker: "Fyers",
      message: "Please provide a valid target value",
      description:
        "This error specifies that the takeProfit key being passed is incorrect or the value in takeProfit key is inappropriate",
    },
    {
      code: "-326",
      broker: "Fyers",
      message: "Please provide a valid order validity",
      description: "This error specifies that the Validity key value provided for the particular order is incorrect",
    },
    {
      code: "-327",
      broker: "Fyers",
      message: "Please provide a valid order disclosed quantity",
      description: "This error specifies the disclosed quantity passed for a particular value is incorrect",
    },
    {
      code: "-328",
      broker: "Fyers",
      message: "Please provide a valid order offline flag",
      description:
        "This error specifes that the offline order flag provided is incorrect it should be either true/false is being passed",
    },
    {
      code: "-201",
      broker: "Fyers",
      message: "Connection error. Please try again",
      description: "This error specifies that there was some connection issue while processing your request",
    },
    {
      code: "-202",
      broker: "Fyers",
      message: "Connection error. Please try again",
      description:
        "This error specifies that due to some cause the connection timed out. after some time make another request",
    },
    {
      code: "-204",
      broker: "Fyers",
      message: "Connection error. Please try again",
      description: "This error specifies due to some http error the request wasnt processed",
    },
    {
      code: "-205",
      broker: "Fyers",
      message: "Connection error. Please try again",
      description: "This error specifies due to some reason the market wasnt able to accept or process the request",
    },
    {
      code: "-157",
      broker: "Fyers",
      message: "",
      description: "This error specifies that the user doesnt exist",
    },
    {
      code: "-159",
      broker: "Fyers",
      message: "Please provide a valid order number",
      description:
        "This error specifies that for the particular order_id provided there is no any orders in the orderbook",
    },
    {
      code: "-161",
      broker: "Fyers",
      message: "This order has already been cancelled",
      description: "This error specifies that the order for the particular order_id is already cancelled",
    },
    {
      code: "-162",
      broker: "Fyers",
      message: "This order has already traded",
      description: "This error specifies that the order for the particular order_id is already traded",
    },
    {
      code: "-163",
      broker: "Fyers",
      message: "",
      description: "This error specifies that due to some reason the order modification wasnt done successfully",
    },
    {
      code: "-164",
      broker: "Fyers",
      message: "This order has already been rejected",
      description: "This error specifies that the order for the particular symbol got rejected due to some reason",
    },
    {
      code: "-390",
      broker: "Fyers",
      message: "Please provide a valid stop price",
      description:
        "This error specfies that during order placement the stopPrice provided is invalid for the particular symbol",
    },
    {
      code: "-392",
      broker: "Fyers",
      message: "Price should be in multiples of tick size",
      description:
        "This error specifies that Price you are trading for the symbol doesnt match with the symbol tick size",
    },
    {
      code: "-353",
      broker: "Fyers",
      message: "API Limit exceeded (perSec/perMin/perDay)",
      description: "This error specifies that the ratelimit for the Api is exceeded",
    },
    {
      code: "-372",
      broker: "Fyers",
      message: "Please provide a valid price",
      description: "This error specifies that the Price provided during orderplacement is invalid",
    },
    {
      code: "-397",
      broker: "Fyers",
      message: "Position quantity is zero",
      description: "This error specifies that for the symbol the Position is already Zero",
    },
    {
      code: "-398",
      broker: "Fyers",
      message: "Looks like you have no open positions",
      description: "This error specifies that the you dont have any open positions in your account",
    },
    {
      code: "-399",
      broker: "Fyers",
      message: "No pending orders",
      description:
        "This error specifies that you dont have any order which can be cancelled or you dont have any position to close the particular position",
    },
    {
      code: "-329",
      broker: "Fyers",
      message: "We have processed exit positions request. Kindly check positions before taking further action",
      description:
        "This error specifies that we have made the request to the market to close position but the result havent been updated so for you confirmation we are asking you to check the positions",
    },
    {
      code: "-373",
      broker: "Fyers",
      message: "please provide valid permission",
      description: "This error specifies that for the particular Api request the app doesnt have the permission",
    },
    {
      code: "-374",
      broker: "Fyers",
      message: "Please provide authorization code",
      description:
        "This error specifies that the auth_code passed is not a valid one you need to generate one to generate token",
    },
    {
      code: "-371",
      broker: "Fyers",
      message: "Please provide sha256 hash of appId and app secret",
      description:
        "This error specifies that the app_id hash passed to generate the token is invaliad and you need to provide one with the sha256(app_id:secret_key)",
    },
    {
      code: "-96",
      broker: "Fyers",
      message: "An unexpected error occurred. Please contact support",
      description: "This error occurs if there comes an issue while processing the request",
    },
 
      // HDFC Securities
      {
        code: "0",
        broker: "HDFC",
        message: "Success",
        description: "Operation completed successfully",
      },
      {
        code: "1",
        broker: "HDFC",
        message: "Failed",
        description: "Operation failed",
      },
      {
        code: "2",
        broker: "HDFC",
        message: "Invalid Password",
        description: "The provided password is invalid",
      },
      {
        code: "3",
        broker: "HDFC",
        message: "Used & Expired Password",
        description: "The password has been used and is now expired",
      },
      {
        code: "4",
        broker: "HDFC",
        message: "Blocked Password",
        description: "The password has been blocked",
      },
      {
        code: "5",
        broker: "HDFC",
        message: "Bad Input",
        description: "The provided input is invalid",
      },
      {
        code: "6",
        broker: "HDFC",
        message: "DB Error",
        description: "Database operation error occurred",
      },
      {
        code: "7",
        broker: "HDFC",
        message: "Internal Server Error",
        description: "Internal server error occurred",
      },
      {
        code: "8",
        broker: "HDFC",
        message: "No valid Password in system",
        description: "No valid password exists in the system",
      },
      {
        code: "9",
        broker: "HDFC",
        message: "Unused & Expired",
        description: "The password was never used and has expired",
      },
      {
        code: "10",
        broker: "HDFC",
        message: "Blocked link data",
        description: "The link data has been blocked",
      },
      {
        code: "99",
        broker: "HDFC",
        message: "General Error",
        description: "A general error occurred",
      },
      {
        code: "TH99401",
        broker: "HDFC",
        message: "SSL Required For API Access",
        description: "401 Unauthorized - SSL connection is required for API access",
      },
      {
        code: "TH99401",
        broker: "HDFC",
        message: "Access denied for the IP",
        description: "401 Unauthorized - IP address is not whitelisted or allowed",
      },
      {
        code: "TH99405",
        broker: "HDFC",
        message: "Request Method Not Allowed For API Access",
        description: "405 Method Not Allowed - The HTTP method used is not supported",
      },
      {
        code: "TH99413",
        broker: "HDFC",
        message: "Message Size Exceeded Limit",
        description: "413 Payload Too Large - Request payload size exceeds allowed limit",
      },
      {
        code: "TH99429",
        broker: "HDFC",
        message: "DDOS Attack Detected from Requester",
        description: "429 Too Many Requests - Suspicious request pattern detected",
      },
      {
        code: "TH99410",
        broker: "HDFC",
        message: "Cross Site Forgery Detected in API Request Message Payload",
        description: "410 Gone - CSRF attack detected in request",
      },
      {
        code: "TH99400",
        broker: "HDFC",
        message: "Code Injection Detected in API Request Message Payload",
        description: "400 Bad Request - Malicious code detected in request",
      },
      {
        code: "TH99421",
        broker: "HDFC",
        message: "Document Structure Threat in API Request Message Payload",
        description: "421 Misdirected Request - Invalid document structure detected",
      },
      {
        code: "TH99422",
        broker: "HDFC",
        message: "SQL Injection Detected in API Request Message Payload",
        description: "422 Unprocessable Entity - SQL injection attempt detected",
      },
      {
        code: "TH99411",
        broker: "HDFC",
        message: "Invalid Content Type in API Request Message Payload",
        description: "411 Length Required - Content-Type header is missing or invalid",
      },
      {
        code: "TH99503",
        broker: "HDFC",
        message: "API Back-end Service Not Available or Timed-Out",
        description: "503 Service Unavailable - Backend service is not responding",
      },
      {
        code: "TH99429",
        broker: "HDFC",
        message: "API Access Quota Exceeded",
        description: "429 Too Many Requests - API rate limit exceeded",
      },
      {
        code: "TH99423",
        broker: "HDFC",
        message: "XML Entity Expansion Attack Detected from Requester",
        description: "423 Locked - XML security threat detected",
      },
      {
        code: "TH99500",
        broker: "HDFC",
        message: "Backend Service Provided Unexpected Response",
        description: "500 Internal Server Error - Unexpected backend response",
      },
      {
        code: "TH99409",
        broker: "HDFC",
        message: "Message Payload Validation failed",
        description: "409 Conflict - Request payload validation failed",
      },
      {
        code: "TH99412",
        broker: "HDFC",
        message: "Oauth Token Validation failed",
        description: "412 Precondition Failed - OAuth token is invalid",
      },
      {
        code: "TH99412",
        broker: "HDFC",
        message: "You Are Not Authorized To Access This Functionality",
        description: "412 Precondition Failed - Insufficient permissions",
      },
      {
        code: "TH99401",
        broker: "HDFC",
        message: "Invalid Client Certificate",
        description: "401 Unauthorized - Client certificate validation failed",
      },
      {
        code: "TH99401",
        broker: "HDFC",
        message: "Scope Validation Failed",
        description: "401 Unauthorized - Request scope is invalid",
      },
      {
        code: "TH99401",
        broker: "HDFC",
        message: "Signature verification Failed",
        description: "401 Unauthorized - Request signature is invalid",
      },
      {
        code: "TH99401",
        broker: "HDFC",
        message: "Invalid API Key",
        description: "401 Unauthorized - API key is invalid or expired",
      },
      {
        code: "TH99400",
        broker: "HDFC",
        message: "Decryption Failed",
        description: "400 Bad Request - Unable to decrypt request payload",
      },
      {
        code: "TH99400",
        broker: "HDFC",
        message: "Bad Request",
        description: "400 Bad Request - Generic bad request error",
      },
      {
        code: "TH99509",
        broker: "HDFC",
        message: "Account Plan limit exceeded",
        description: "509 Bandwidth Limit Exceeded - Account usage limit reached",
      },
    
      // IIFL Securities
      {
        code: "E01",
        broker: "IIFL",
        message: "Cannot be empty",
        description: "Required field is missing or empty",
      },
      {
        code: "E02",
        broker: "IIFL",
        message: "Prospect is an existing iifl customer",
        description: "Customer already exists in IIFL database",
      },
      {
        code: "E03",
        broker: "IIFL",
        message: "Lead already exists",
        description: "A lead with these details already exists in the system",
      },
      {
        code: "E04",
        broker: "IIFL",
        message: "Lead id does not exists",
        description: "The specified lead ID was not found",
      },
      {
        code: "E05",
        broker: "IIFL",
        message: "Applied loan amount is not provided",
        description: "Loan amount field is required but missing",
      },
      {
        code: "E06",
        broker: "IIFL",
        message: "Tenure is not provided",
        description: "Loan tenure field is required but missing",
      },
      {
        code: "E07",
        broker: "IIFL",
        message: "Roi interest is not provided",
        description: "Rate of interest field is required but missing",
      },
      {
        code: "E08",
        broker: "IIFL",
        message: "Lead does not meet minimum credit parameters",
        description: "Application does not meet minimum credit criteria",
      },
      {
        code: "E09",
        broker: "IIFL",
        message: "Invalid source code",
        description: "The provided source code is not valid",
      },
      {
        code: "E10",
        broker: "IIFL",
        message: "Token has been expired",
        description: "Authentication token has expired",
      },
      {
        code: "E11",
        broker: "IIFL",
        message: "Invalid request code",
        description: "The request code provided is not valid",
      },
      {
        code: "E12",
        broker: "IIFL",
        message: "Lead already exist with the enter mobile number",
        description: "A lead with this mobile number already exists",
      },
      {
        code: "E13",
        broker: "IIFL",
        message: "Invalid token",
        description: "The provided authentication token is invalid",
      },
      {
        code: "E14",
        broker: "IIFL",
        message: "Poor credit history",
        description: "Applicant has poor credit history",
      },
      {
        code: "E15",
        broker: "IIFL",
        message: "Permanent city code is not valid",
        description: "Invalid permanent city code provided",
      },
      {
        code: "E16",
        broker: "IIFL",
        message: "Current state code is not valid",
        description: "Invalid current state code provided",
      },
      {
        code: "E17",
        broker: "IIFL",
        message: "Permanent state code is not valid",
        description: "Invalid permanent state code provided",
      },
      {
        code: "E18",
        broker: "IIFL",
        message: "Current city code is not valid",
        description: "Invalid current city code provided",
      },
      {
        code: "E19",
        broker: "IIFL",
        message: "An internal error from our side",
        description: "Internal server error occurred",
      },
      {
        code: "E20",
        broker: "IIFL",
        message: "Unable to proceed!!!",
        description: "System unable to process the request",
      },
      {
        code: "E21",
        broker: "IIFL",
        message: "Aadhaar number is not valid format",
        description: "Invalid Aadhaar number format",
      },
      {
        code: "E22",
        broker: "IIFL",
        message: "Pan number is not valid format",
        description: "Invalid PAN number format",
      },
      {
        code: "E23",
        broker: "IIFL",
        message: "Processing fee is not valid format",
        description: "Invalid processing fee format",
      },
      {
        code: "E24",
        broker: "IIFL",
        message: "Monthly salary is not valid format",
        description: "Invalid monthly salary format",
      },
      {
        code: "E25",
        broker: "IIFL",
        message: "Monthly obligation is not valid format",
        description: "Invalid monthly obligation format",
      },
      {
        code: "E26",
        broker: "IIFL",
        message: "Applied loan amount is not valid format",
        description: "Invalid loan amount format",
      },
      {
        code: "E27",
        broker: "IIFL",
        message: "Roi is not valid format",
        description: "Invalid rate of interest format",
      },
      {
        code: "E28",
        broker: "IIFL",
        message: "Tenure is not valid format",
        description: "Invalid tenure format",
      },
      {
        code: "E29",
        broker: "IIFL",
        message: "Total payable amount is not valid format",
        description: "Invalid total payable amount format",
      },
      {
        code: "E30",
        broker: "IIFL",
        message: "Dob is not valid format",
        description: "Invalid date of birth format",
      },
      {
        code: "E31",
        broker: "IIFL",
        message: "First name required atleast 3 charatcers",
        description: "First name must be at least 3 characters long",
      },
      {
        code: "E32",
        broker: "IIFL",
        message: "First name does not allow special charatcers",
        description: "First name contains invalid special characters",
      },
      {
        code: "E33",
        broker: "IIFL",
        message: "Middle name does not allow special charatcers",
        description: "Middle name contains invalid special characters",
      },
      {
        code: "E34",
        broker: "IIFL",
        message: "Last name does not allow special charatcers",
        description: "Last name contains invalid special characters",
      },
      {
        code: "E35",
        broker: "IIFL",
        message: "Mobile number is not valid format",
        description: "Invalid mobile number format",
      },
      {
        code: "E36",
        broker: "IIFL",
        message: "Personal email id is not valid format",
        description: "Invalid email format",
      },
      {
        code: "E37",
        broker: "IIFL",
        message: "Current pin is not valid format",
        description: "Invalid current PIN code format",
      },
      {
        code: "E38",
        broker: "IIFL",
        message: "Permanent pin is not valid format",
        description: "Invalid permanent PIN code format",
      },
      {
        code: "E39",
        broker: "IIFL",
        message: "Company pin is not valid format",
        description: "Invalid company PIN code format",
      },
      {
        code: "E40",
        broker: "IIFL",
        message: "Alternate mobile no is not valid format",
        description: "Invalid alternate mobile number format",
      },
      {
        code: "E41",
        broker: "IIFL",
        message: "Cocurrentpin is not valid format",
        description: "Invalid co-applicant current PIN format",
      },
      {
        code: "E42",
        broker: "IIFL",
        message: "Copermanentpin is not valid format",
        description: "Invalid co-applicant permanent PIN format",
      },
      {
        code: "E43",
        broker: "IIFL",
        message: "Coalternatemobileno is not valid format",
        description: "Invalid co-applicant alternate mobile number format",
      },
      {
        code: "E44",
        broker: "IIFL",
        message: "Coaadhaarnumber is not valid format",
        description: "Invalid co-applicant Aadhaar number format",
      },
      {
        code: "E45",
        broker: "IIFL",
        message: "Kycflag is not valid format",
        description: "Invalid KYC flag format",
      },
      {
        code: "E46",
        broker: "IIFL",
        message: "Prospect created but error in running cibil",
        description: "Error occurred while checking CIBIL score",
      },
      {
        code: "E47",
        broker: "IIFL",
        message: "Search parameter does not match with state master",
        description: "Invalid state search parameter",
      },
      {
        code: "E48",
        broker: "IIFL",
        message: "Invalid pan card number",
        description: "PAN card number is invalid",
      },
      {
        code: "E49",
        broker: "IIFL",
        message: "Unable to validate",
        description: "System unable to validate the request",
      },
      {
        code: "E50",
        broker: "IIFL",
        message: "Kyc details already exists",
        description: "KYC details already exist in the system",
      },
      {
        code: "E51",
        broker: "IIFL",
        message: "Disposition code or prospect no does not match",
        description: "Invalid disposition code or prospect number",
      },
      {
        code: "E52",
        broker: "IIFL",
        message: "Bank code does not exist",
        description: "Invalid bank code provided",
      },
      {
        code: "E53",
        broker: "IIFL",
        message: "Invalid prospect number",
        description: "The prospect number is not valid",
      },
      {
        code: "E54",
        broker: "IIFL",
        message: "Bank details already exists",
        description: "Bank details already exist in the system",
      },
      {
        code: "E55",
        broker: "IIFL",
        message: "Coapplicant already exist",
        description: "Co-applicant details already exist",
      },
      {
        code: "E56",
        broker: "IIFL",
        message: "Error while creating documents file",
        description: "Failed to create document files",
      },
      {
        code: "E57",
        broker: "IIFL",
        message: "Category name cannot be blank",
        description: "Category name is required",
      },
      {
        code: "E58",
        broker: "IIFL",
        message: "Search category name does not match with masters",
        description: "Invalid category search parameter",
      },
      {
        code: "E59",
        broker: "IIFL",
        message: "Ekyc for existing lead has been completed",
        description: "E-KYC already completed for this lead",
      },
      {
        code: "E60",
        broker: "IIFL",
        message: "Emailid is not valid format",
        description: "Invalid email format",
      },
      {
        code: "E61",
        broker: "IIFL",
        message: "Pin is not valid format",
        description: "Invalid PIN code format",
      },
      {
        code: "E62",
        broker: "IIFL",
        message: "From date should be less than to date",
        description: "Invalid date range provided",
      },
      {
        code: "E63",
        broker: "IIFL",
        message: "From Date is not in valid format",
        description: "Invalid from date format",
      },
      {
        code: "E64",
        broker: "IIFL",
        message: "To Date is not in valid format",
        description: "Invalid to date format",
      },
      {
        code: "E65",
        broker: "IIFL",
        message: "Scheme or SubScheme does not match",
        description: "Invalid scheme or sub-scheme",
      },
      {
        code: "E66",
        broker: "IIFL",
        message: "Amount requested cannot be zero",
        description: "Requested amount must be greater than zero",
      },
      {
        code: "E67",
        broker: "IIFL",
        message: "Amount requested is not valid format",
        description: "Invalid amount format",
      },
      {
        code: "E68",
        broker: "IIFL",
        message: "Requested amount should be greater than or equal to 25000.00",
        description: "Minimum amount requirement not met",
      },
      {
        code: "E69",
        broker: "IIFL",
        message: "Prospect created but error while reading cibil string",
        description: "Error processing CIBIL data",
      },
      {
        code: "E70",
        broker: "IIFL",
        message: "Applicant does not meet minimum age criteria",
        description: "Age requirement not met",
      },
      {
        code: "E71",
        broker: "IIFL",
        message: "Applicant does not meet minimum current work experience criteria",
        description: "Current work experience requirement not met",
      },
      {
        code: "E72",
        broker: "IIFL",
        message: "Applicant does not meet minimum total work experience criteria",
        description: "Total work experience requirement not met",
      },
      {
        code: "E73",
        broker: "IIFL",
        message: "City does not meet MCP parameter",
        description: "City does not meet minimum criteria parameter",
      },
      {
        code: "E74",
        broker: "IIFL",
        message: "Salary is below minimum salary criteria",
        description: "Minimum salary requirement not met",
      },
      {
        code: "E75",
        broker: "IIFL",
        message: "Applicant does not meet minimum residential stability criteria",
        description: "Residential stability requirement not met",
      },
      {
        code: "E76",
        broker: "IIFL",
        message: "Vendor id is mandatory",
        description: "Vendor ID is required",
      },
      {
        code: "E77",
        broker: "IIFL",
        message: "Vendor id does not exist",
        description: "Invalid vendor ID provided",
      },
      {
        code: "E78",
        broker: "IIFL",
        message: "Applicant does not meet minimum credit parameters",
        description: "Credit requirements not met",
      },
      {
        code: "E79",
        broker: "IIFL",
        message: "Applicant does not meet credit parameter for residence type",
        description: "Residence type does not meet credit criteria",
      },
  
   
      // Upstox
      {
        code: "UDAPI10000",
        broker: "Upstox",
        message: "This request is not supported by Upstox API",
        description: "This error is thrown when the API call is not recognized or valid, possibly due to incorrect URL formatting or the presence of unexpected characters in the URL"
      },
      {
        code: "UDAPI100016",
        broker: "Upstox",
        message: "Invalid Credentials",
        description: "Thrown when one of the credentials you've passed to this API is invalid"
      },
      {
        code: "UDAPI10005",
        broker: "Upstox",
        message: "Too Many Request Sent",
        description: "Thrown when you've exceeded the Rate limits for the API"
      },
      {
        code: "UDAPI100015",
        broker: "Upstox",
        message: "API Version does not exist",
        description: "Thrown when API version isn't part of the Header attributes"
      },
      {
        code: "UDAPI100050",
        broker: "Upstox",
        message: "Invalid token used to access API",
        description: "Thrown when an invalid token is used to access the API"
      },
      {
        code: "UDAPI100067",
        broker: "Upstox",
        message: "The API you are trying to access is not permitted with an extended_token",
        description: "Thrown when trying to access an API that is not allowed with an extended_token"
      },
      {
        code: "UDAPI100036",
        broker: "Upstox",
        message: "Invalid Input",
        description: "Thrown when an invalid input is passed to the API"
      },
      {
        code: "UDAPI100038",
        broker: "Upstox",
        message: "Invalid input passed to the API",
        description: "Thrown when an invalid input is passed to the API"
      },
      {
        code: "UDAPI100073",
        broker: "Upstox",
        message: "Your 'client_id' is inactive",
        description: "Thrown when the client_id is not active. Please contact the support team for further assistance"
      },
      {
        code: "UDAPI100500",
        broker: "Upstox",
        message: "Something went wrong... please contact us",
        description: "An unexpected error occurred. Please contact support"
      },
   // Zerodha
   {
    code: "400",
    broker: "Zerodha",
    message: "Missing or bad request parameters or values",
    description: "Request contains invalid parameters or values",
  },
  {
    code: "403",
    broker: "Zerodha",
    message: "Session expired or invalidate. Must relogin",
    description: "Authentication session has expired and requires re-login",
  },
  {
    code: "404",
    broker: "Zerodha",
    message: "Request resource was not found",
    description: "The requested API endpoint or resource does not exist",
  },
  {
    code: "405",
    broker: "Zerodha",
    message: "Request method (GET, POST etc.) is not allowed on the requested endpoint",
    description: "The HTTP method used is not supported for this endpoint",
  },
  {
    code: "410",
    broker: "Zerodha",
    message: "The requested resource is gone permanently",
    description: "The resource you're trying to access has been permanently removed",
  },
  {
    code: "429",
    broker: "Zerodha",
    message: "Too many requests to the API (rate limiting)",
    description: "API rate limit has been exceeded",
  },
  {
    code: "500",
    broker: "Zerodha",
    message: "Something unexpected went wrong",
    description: "Internal server error occurred",
  },
  {
    code: "502",
    broker: "Zerodha",
    message: "The backend OMS is down and the API is unable to communicate with it",
    description: "Order Management System is unavailable",
  },
  {
    code: "503",
    broker: "Zerodha",
    message: "Service unavailable; the API is down",
    description: "API service is currently unavailable",
  },
  {
    code: "504",
    broker: "Zerodha",
    message: "Gateway timeout; the API is unreachable",
    description: "API gateway timed out while trying to process the request",
  },
    ]
  };