export const brokerParams = {
  aliceblue: {
    required: ["clientId", "apiKey", "accessToken"],
    description: "<b>clientId, apiKey, accessToken</b>"
  },

  angelone: {
    required: ["apiKey", "accessToken"],
    description: "<b>apiKey, accessToken</b>"
  },
  dhan: {
    required: ["clientId", "accessToken"],
    description: "<b>clientId, accessToken</b>"
  },

  hdfc: {
    required: ["apiKey", "accessToken"],
    description: "<b>apiKey, accessToken</b>"
  },
  fyers: {
    required: ["clientId", "accessToken"],
    description: "<b>clientId, accessToken</b>"
  },
  iifl: {
    required: ["clientCode"],
    description: "<b>clientCode</b>"
  },
  icici: {
    required: ["apiKey", "secretKey", "accessToken"],
    description: "<b>apiKey, secretKey, accessToken</b>"
  },
  kotak: {
    required: ["consumerKey", "consumerSecret", "mobileNumber", "password", "mpin"],
    description: "<b> consumerKey,consumerSecret, mobileNumber, password, mpin</b>"
  },
  upstox: {
    required: ["apiKey", "apiSecret", "accessToken"],
    description: "<b>apiKey, apiSecret, accessToken</b>"
  },
  zerodha: {
    required: ["apiKey", "accessToken"],
    description: "<b>apiKey, accessToken</b>"
  }


};

export const commonHeaders = {
  'aq-api-key': '12345',
  'aq-signature': 'f0e18e59b1921529c3856ddb1e6b799d67f05e9aeea37aec82a366d6cfd4ae18',
  'Content-Type': 'application/json'
};

export const commonText = {
  requestBodyIntro: "Please provide the required parameters as per the request body below in broker requests."
};


export const sectionDescriptions = {
  singleOrderStatus: {
    
    hdfc: {
      required: ["apiKey", "accessToken", "orderid"],
      description: "<b>apiKey, accessToken,orderid</b>"
    },
    upstox: {
      required: ["apiKey", "apiSecret", "accessToken", "uniqueOrderId "],
      description: "<b>apiKey, apiSecret, accessToken,uniqueOrderId </b>"
    },
    aliceblue: {
      required: ["clientId", "apiKey", "accessToken", "orderid"],
      description: "<b>clientId, apiKey, accessToken, orderid</b>"
    },
    fyers: {
      required: ["clientId", "accessToken", "uniqueOrderId"],
      description: "<b>clientId, accessToken,uniqueOrderId</b>"
    },
    angelone: {
      required: ["apiKey", "accessToken", "uniqueorderid"],
      description: "<b>apiKey, accessToken,uniqueorderid</b>"
    },
    dhan: {
      required: ["clientId", "accessToken", "uniqueOrderId"],
      description: "<b>clientId, accessToken,uniqueOrderId</b>"
    },
    icici: {
      required: ["apiKey", "secretKey", "accessToken", "uniqueOrderId"],
      description: "<b>apiKey, secretKey, accessToken, uniqueOrderId</b>"
    },
    kotak: {
      required: ["consumerKey", "consumerSecret", "mobileNumber", "viewToken", "password", "mpin", "orderId", "serverId"],
      description: "<b> consumerKey,consumerSecret, mobileNumber,viewToken, password, mpin,orderId,serverId</b>"
    },
    zerodha: {
      required: ["apiKey", "accessToken", "orderId"],
      description: "<b>apiKey, accessToken,orderId</b>"
    }
  },

  orderStatuses: {
    aliceblue: {
      required: ["clientId", "apiKey", "accessToken","orderIds"],
      description: "<b>clientId, apiKey, accessToken,orderIds</b>"
    },
    angelone: {
      required: ["apiKey", "accessToken","uniqueorderids"],
      description: "<b>apiKey, accessToken,uniqueorderids</b>"
    },
    dhan: {
      required: ["clientId", "accessToken","orderIds"],
      description: "<b>clientId, accessToken,orderIds</b>"
    },
    fyers: {
      required: ["clientId", "accessToken","uniqueOrderIds "],
      description: "<b>clientId, accessToken,uniqueOrderIds</b>"
    },
  
    hdfc: {
      required: ["apiKey", "accessToken", "orderIds"],
      description: "<b>apiKey, accessToken,orderIds</b>"
    },
    
    icici: {
      required: ["apiKey", "secretKey", "accessToken","uniqueOrderIds"],
      description: "<b>apiKey, secretKey, accessToken,uniqueOrderIds</b>"
    },
    iifl: {
      required: ["accessToken","orderStatusReqList"],
      description: "<b>accessToken,orderStatusReqList</b>"
    },
 

    kotak: {
      required: ["consumerKey", "consumerSecret", "accessToken", "viewToken", "orderIds","sid","serverId"],
      description: "<b> consumerKey,consumerSecret, accessToken, viewToken, orderIds,sid,serverId</b>"
    },
    upstox: {
      required: ["apiKey", "apiSecret", "accessToken", "uniqueOrderIds "],
      description: "<b>apiKey, apiSecret, accessToken,uniqueOrderIds </b>"
    },
    zerodha: {
      required: ["apiKey","accessToken","orderIds"],
      description: "<b>apiKey,accessToken,orderIds</b>"
    },
  },



  funds: {
    aliceblue: {
      required: ["clientId", "apiKey", "accessToken"],
      description: "<b>clientId, apiKey, accessToken</b>"
    },
    fyers: {
      required: ["clientId", "accessToken"],
      description: "<b>clientId, accessToken</b>"
    },

  },



  positions: {
    fyers: {
      required: ["clientId", "accessToken"],
      description: "<b>clientId, accessToken</b>"
    },
  },
  cancelOrder: {
    angelone: {
      required: ["apiKey", "accessToken", "variety", "orderrId"],
      description: "<b>apiKey, accessToken,variety,orderId</b>"
    },
  },



  placeOrder: {
    angelone: {
      required: ["apiKey", "accessToken", "order"],
      description: "<b>apiKey, accessToken,order</b>"
    },
    fyers: {
      required: ["clientId", "accessToken", "order"],
      description: "<b>clientId, accessToken,order</b>"
    },

    dhan: {
      required: ["clientId", "accessToken", "order"],
      description: "<b>clientId, accessToken,order</b>"
    },
    hdfc: {
      required: ["apiKey", "accessToken", "order"],
      description: "<b>apiKey, accessToken,order</b>"
    },
    icici: {
      required: ["apiKey", "secretKey", "accessToken", "order"],
      description: "<b>apiKey, secretKey, accessToken, order</b>"
    },
    aliceblue: {
      required: ["clientId", "apiKey", "accessToken", "order"],
      description: "<b>clientId, apiKey, accessToken,order</b>"
    },
    kotak: {
      required: ["consumerSecret", "consumerKey", "accessToken", "viewToken", "sid", "serverId", "order"],
      description: "<b>consumerSecret, consumerKey, accessToken, viewToken, sid, serverId,order</b>"
    },
    upstox: {
      required: ["apiKey", "apiSecret", "accessToken", "order "],
      description: "<b>apiKey, apiSecret, accessToken,order </b>"
    },
    zerodha: {
      required: [""],
      description: ""
    },
  },


  modifyOrder: {
    angelone: {
      required: ["apiKey", "accessToken", "order"],
      description: "<b>apiKey, accessToken,order</b>"
    },
  },

  profile: {
    angelone: {
      required: ["apiKey", "accessToken", "refreshToken"],
      description: "<b>apiKey, accessToken,refreshToken</b>"
    },
    dhan: {
      required: ["N/A"],
      description: "<b>N/A</b>"
    },
    icici: {
      required: ["N/A"],
      description: "<b>N/A</b>"
    },
    aliceblue: {
      required: ["N/A"],
      description: "<b>N/A</b>"
    },
    fyers: {
      required: ["N/A"],
      description: "<b>N/A</b>"
    },
  },


  processTrades: {
    icici: {
      required: ["apiKey", "secretKey", "accessToken", "uniqueOrderId","trades"],
      description: "<b>apiKey, secretKey, accessToken, uniqueOrderId,trades</b>"
    },
    hdfc: {
      required: ["apiKey", "accessToken", "trades"],
      description: "<b>apiKey, accessToken,trades</b>"
    },

    upstox: {
      required: ["apiKey", "apiSecret", "accessToken", "trades "],
      description: "<b>apiKey, apiSecret, accessToken,trades </b>"
    },
    fyers: {
      required: ["clientId", "accessToken", "trades"],
      description: "<b>clientId, accessToken,trades</b>"
    },

    aliceblue: {
      required: ["clientId", "apiKey", "accessToken", "trades"],
      description: "<b>clientId, apiKey, accessToken,trades</b>"
    },

    angelone: {
      required: ["apiKey", "accessToken", "trades"],
      description: "<b>apiKey, accessToken,trades</b>"
    },
    dhan: {
      required: ["clientId", "accessToken", "trades"],
      description: "<b>clientId, accessToken,trades</b>"
    },
    iifl: {
      required: ["clientCode", "trades"],
      description: "<b>clientCode,trades</b>"
    },
    kotak: {
      required: ["consumerSecret", "consumerKey", "accessToken", "viewToken", "sid", "serverId", "trades"],
      description: "<b>consumerSecret, consumerKey, accessToken, viewToken, sid, serverId,trades</b>"
    },

    zerodha: {
      required: ["apiKey", "accessToken", "userEmail", "returnDateTime", "trades"],
      description: "<b>apiKey, accessToken,userEmail,returnDateTime,trades</b>"
    },


  },

};